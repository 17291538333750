export const state = () => ({
  reward_notification: null,
})

export const getters = {
  get_reward_notification(state) {
    return state.reward_notification
  },
}

export const mutations = {
  set_reward_notification(state, payload) {
    state.reward_notification = payload
  },
}
