import { sha256 } from 'js-sha256'
import FingerprintJS from '@fingerprintjs/fingerprintjs'

export const state = () => ({
  endpoints: {
    user_settings: '/v4/users/me/settings',
    freemium: '/users/me/freemium',
  },
  freemium_hash: null,
  loyalty_level: null,
  settings: {},
})

export const getters = {
  get_is_loyalty_welcome_viewed(state) {
    const { is_loyalty_welcome_viewed } = state.settings
    return !!is_loyalty_welcome_viewed
  },
  get_company_invitations(state) {
    const { company_invitations } = state.settings
    return company_invitations?.length ? company_invitations : []
  },
}

export const mutations = {
  set_loyalty_level(state, payload) {
    state.loyalty_level = payload
  },

  set_settings(state, newSettings) {
    state.settings = {
      ...state.settings,
      ...newSettings,
    }
  },

  set_freemium_hash(state, hash) {
    state.freemium_hash = hash
  },
}

export const actions = {
  async fetch_settings({ state, rootGetters, commit, dispatch }) {
    try {
      const { data } = await this.$axios.get(state.endpoints.user_settings)
      let newCurrencyCode = null

      //  See UXP-2700 comments for details on this logic
      if (data.currency !== rootGetters['multiCurrency/get_current_currency_code']) {
        newCurrencyCode = data.currency || rootGetters['multiCurrency/get_current_currency_code']
      }

      if (newCurrencyCode) {
        await dispatch(
          'multiCurrency/set_currency',
          {
            newCurrencyCode,
            shouldUpdateProfile: !data.currency,
          },
          { root: true }
        )
      }
      commit('set_settings', data)
    } catch (error) {
      console.error(error)
      return null
    }
  },

  async fetch_freemium_hash({ commit, state }) {
    const fp = await FingerprintJS.load()
    const { visitorId } = await fp.get()
    const fingerprint = visitorId
    const timestamp = Math.floor(Date.now() / 1000).toString()
    const app = 'web'
    const dataString = `${fingerprint}${app}${timestamp}`
    const secretKey = process.env.FREEMIUM_HASH_KEY
    const hmac = sha256.hmac(secretKey, dataString)

    try {
      const freemiumData = await this.$axios.post(state.endpoints.freemium, {
        fingerprint,
        timestamp,
        app,
        hmac,
      })

      if (freemiumData.data.hash) {
        commit('set_freemium_hash', freemiumData.data.hash)
      }
    } catch (e) {
      console.error(e)
    }
  },

  async post_settings({ commit, state }, newSettings) {
    try {
      const { data } = await this.$axios.put(state.endpoints.user_settings, newSettings)
      commit('set_settings', data)
    } catch (error) {
      console.error(error)
      return null
    }
  },
}
