var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    class: ['c--shared_header header', {
      'd-none d-sm-block': _vm.isMyESIMSDetailPages
    }]
  }, [_c('div', {
    staticClass: "header-content d-flex align-items-center"
  }, [_c('HeaderLogo', {
    attrs: {
      "branding": _vm.branding,
      "is-checkout-page": _vm.isCheckoutPage
    }
  }), _vm._v(" "), _vm.isCheckoutPage && _vm.deviceType.isDesktop && _vm.isAuthenticated || !_vm.isCheckoutPage && _vm.isAuthenticated ? [_c('div', {
    class: {
      'ml-auto': _vm.$dir() === 'ltr',
      'mr-auto': _vm.$dir() === 'rtl'
    }
  }, [_c('Airmoney')], 1)] : _vm._e(), _vm._v(" "), _c('div', {
    class: ['header-navigation d-none', {
      'd-sm-block': !_vm.deviceType.isMobile && !_vm.deviceType.isTablet,
      'ml-auto': !_vm.isAuthenticated && _vm.$dir() === 'ltr',
      'mr-auto': !_vm.isAuthenticated && _vm.$dir() === 'rtl'
    }]
  }, [_c('nav', [_vm.isAuthenticated ? _c('ul', {
    class: ['cf d-flex align-items-center flex-wrap justify-content-end', {
      'border-l border-l-solid color-border-left-light pl-10 ml-10 pl-lg-20 ml-lg-20': _vm.$dir() === 'ltr',
      'border-r border-r-solid color-border-right-light pr-10 mr-10 pr-lg-20 mr-lg-20': _vm.$dir() === 'rtl'
    }]
  }, [_vm._l(_vm.navigationItems.loggedIn, function (item, index) {
    return _c('li', {
      key: `header-item-v2-${index}`,
      class: {
        'nav-item-with-sub-menu': item.subItems && item.subItems.length > 0
      },
      attrs: {
        "data-testid": "account-menu"
      }
    }, [_c('header-item-block', {
      attrs: {
        "item": item,
        "is-slot": item.isSlot,
        "dir": _vm.$dir()
      }
    }, [item.name === 'user' ? [_c('span', {
      staticClass: "arrow-icon",
      class: {
        'ml-10': _vm.$dir() === 'ltr',
        'mr-10': _vm.$dir() === 'rtl'
      }
    }, [_c('span', {
      staticClass: "airalo-icon-arrow-down tw-text-2 airalo-icon-wrapper"
    })])] : _vm._e()], 2), _vm._v(" "), item.subItems && item.subItems.length > 0 ? [_c('div', {
      staticClass: "header-sub-menu-wrapper"
    }, [_c('header-sub-menu', {
      attrs: {
        "type": 'small',
        "list": item.subItems
      },
      on: {
        "onClickItem": _vm.onClickSubMenuPreventItem
      }
    })], 1)] : _vm._e()], 2);
  }), _vm._v(" "), !_vm.isError ? [_c('li', {
    on: {
      "click": function ($event) {
        _vm.currenciesModalView = true;
      }
    }
  }, [_c('header-item-block', {
    attrs: {
      "item": _vm.currencyItem,
      "dir": _vm.currencyItem.dir
    }
  })], 1)] : _vm._e(), _vm._v(" "), _c('li', {
    on: {
      "click": function ($event) {
        _vm.languagesModalView = true;
      }
    }
  }, [_c('header-item-block', {
    attrs: {
      "item": _vm.languageItem,
      "dir": _vm.languageItem.dir,
      "data-testid": "language-header-btn"
    }
  })], 1)], 2) : _c('ul', {
    staticClass: "cf d-flex align-items-center flex-wrap justify-content-end"
  }, [_vm._l(_vm.navigationItems.loggedOut, function (item, index) {
    var _item$name;
    return _c('li', {
      key: `header-item-v2-${index}`,
      class: {
        'nav-item-with-sub-menu': item.subItems && item.subItems.length > 0
      },
      attrs: {
        "data-testid": `nav-item-${(_item$name = item.name) !== null && _item$name !== void 0 ? _item$name : index}`
      }
    }, [_c('header-item-block', {
      attrs: {
        "item": item,
        "is-slot": item.isSlot,
        "dir": _vm.$dir()
      }
    }, [['partner-with-us', 'about-us'].includes(item.name) ? [_c('span', {
      staticClass: "arrow-icon",
      class: {
        'ml-10': _vm.$dir() === 'ltr',
        'mr-10': _vm.$dir() === 'rtl'
      }
    }, [_c('i', {
      staticClass: "airalo-icon-arrow-down tw-text-2 airalo-icon-wrapper"
    })])] : _vm._e()], 2), _vm._v(" "), item.subItems && item.subItems.length > 0 ? [_c('div', {
      staticClass: "header-sub-menu-wrapper"
    }, [_c('header-sub-menu', {
      attrs: {
        "type": 'small',
        "list": item.subItems
      },
      on: {
        "onClickItem": _vm.onClickSubMenuPreventItem
      }
    })], 1)] : _vm._e()], 2);
  }), _vm._v(" "), !_vm.isError ? [_c('li', {
    on: {
      "click": function ($event) {
        _vm.currenciesModalView = true;
      }
    }
  }, [_c('header-item-block', {
    attrs: {
      "item": _vm.currencyItem,
      "dir": _vm.currencyItem.dir
    }
  })], 1)] : _vm._e(), _vm._v(" "), _c('li', {
    on: {
      "click": function ($event) {
        _vm.languagesModalView = true;
      }
    }
  }, [_c('header-item-block', {
    attrs: {
      "item": _vm.languageItem,
      "dir": _vm.languageItem.dir
    }
  })], 1)], 2)])])], 2), _vm._v(" "), _c('client-only', [_c('log-out-modal', {
    attrs: {
      "modal-view": _vm.isLogoutModalShown,
      "overlay-view": _vm.isLoggingOut,
      "type": "double",
      "text": _vm.$t('components.logout.title'),
      "button": {
        text: _vm.$t('global.cancel'),
        callbackName: 'closeLogOutModal',
        isClose: true
      },
      "button-second": {
        text: _vm.$t('components.logout.positive-button'),
        callbackName: 'logout',
        class: 'red'
      }
    },
    on: {
      "onCloseModal": _vm.closeLogOutModal,
      "onClickButton": _vm.clickLogOutModalActions
    }
  }), _vm._v(" "), _c('currencies-modal', {
    attrs: {
      "modal-view": _vm.currenciesModalView,
      "currencies": _vm.currencies,
      "title": _vm.$t('components.currencies.modal.title'),
      "search-input-placeholder": _vm.$t('placeholder.currencies.modal.currency'),
      "empty-text": _vm.$t('global.no-currency-available')
    },
    on: {
      "onCloseCurrenciesModal": _vm.onCloseCurrenciesModal
    }
  }), _vm._v(" "), _c('languages-modal', {
    attrs: {
      "modal-view": _vm.languagesModalView,
      "languages": _vm.languages,
      "title": _vm.$t('components.languages.modal.title'),
      "search-input-placeholder": _vm.$t('placeholder.languages.modal.language'),
      "empty-text": _vm.$t('global.no-language-available')
    },
    on: {
      "onCloseLanguagesModal": _vm.onCloseLanguagesModal
    }
  })], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }