import { datadogRum } from '@datadog/browser-rum'

export default ({ app }) => {
  // This condition is necessary to avoid running the code in the server while is still as a POC
  if (process.env.DATADOG_APPLICATION_ID) {
    datadogRum.init({
      applicationId: process.env.DATADOG_APPLICATION_ID,
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: process.env.DATADOG_SITE,
      service: process.env.DATADOG_SERVICE,
      env: process.env.DATADOG_ENV,
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    })
  }
}
