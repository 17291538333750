export default class EventManager {
  constructor(app) {
    // const acceptableProviders = ['GTMProvider', 'ImpactProvider', 'ClevertapProvider', 'GTMProviderV2']
    const acceptableProviders = ['GTMProviderV2']

    this.app = app
    this.providers = []

    acceptableProviders.forEach((acc) => {
      const { [acc]: provider } = require(`./providers/${[acc]}`)

      this.providers.push(new provider(app))
    })
  }

  events(eventName, params) {
    this.providers.forEach((provider) => {
      if (typeof provider[eventName] !== 'undefined') {
        provider[eventName](params)
      }
    })
  }
}
