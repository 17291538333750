import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _9b5ef47e = () => interopDefault(import('../pages/about-us/index.vue' /* webpackChunkName: "pages/about-us/index" */))
const _1d29a61b = () => interopDefault(import('../pages/about-us/index/about-airalo/index.vue' /* webpackChunkName: "pages/about-us/index/about-airalo/index" */))
const _733fad1f = () => interopDefault(import('../pages/about-us/index/media-center/index.vue' /* webpackChunkName: "pages/about-us/index/media-center/index" */))
const _778037ef = () => interopDefault(import('../pages/about-us/index/newsroom/index.vue' /* webpackChunkName: "pages/about-us/index/newsroom/index" */))
const _b1086a52 = () => interopDefault(import('../pages/about-us/index/press-releases/index.vue' /* webpackChunkName: "pages/about-us/index/press-releases/index" */))
const _24459f5b = () => interopDefault(import('../pages/about-us/index/newsroom/search.vue' /* webpackChunkName: "pages/about-us/index/newsroom/search" */))
const _042bee5c = () => interopDefault(import('../pages/about-us/index/press-releases/_slug/index.vue' /* webpackChunkName: "pages/about-us/index/press-releases/_slug/index" */))
const _0d231be5 = () => interopDefault(import('../pages/airalo-careers/index.vue' /* webpackChunkName: "pages/airalo-careers/index" */))
const _2e728222 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c9b54b60 = () => interopDefault(import('../pages/index/_list.vue' /* webpackChunkName: "pages/index/_list" */))
const _4d5d6b16 = () => interopDefault(import('../pages/index/_list/_package.vue' /* webpackChunkName: "pages/index/_list/_package" */))
const _df14f3ea = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _fd2bcf64 = () => interopDefault(import('../pages/auth/index/index.vue' /* webpackChunkName: "pages/auth/index/index" */))
const _d6dec088 = () => interopDefault(import('../pages/auth/index/callback/index.vue' /* webpackChunkName: "pages/auth/index/callback/index" */))
const _60e818a0 = () => interopDefault(import('../pages/auth/index/google/index.vue' /* webpackChunkName: "pages/auth/index/google/index" */))
const _1a3b6228 = () => interopDefault(import('../pages/auth/index/login/index.vue' /* webpackChunkName: "pages/auth/index/login/index" */))
const _68f73bcf = () => interopDefault(import('../pages/auth/index/signup/index.vue' /* webpackChunkName: "pages/auth/index/signup/index" */))
const _545b2785 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _18f690f0 = () => interopDefault(import('../pages/campaign.vue' /* webpackChunkName: "pages/campaign" */))
const _71c42845 = () => interopDefault(import('../pages/campaign/referral-terms-and-conditions.vue' /* webpackChunkName: "pages/campaign/referral-terms-and-conditions" */))
const _4ca6fc6e = () => interopDefault(import('../pages/campaign/_slug/index.vue' /* webpackChunkName: "pages/campaign/_slug/index" */))
const _4d343a7e = () => interopDefault(import('../pages/esim-qr-code.vue' /* webpackChunkName: "pages/esim-qr-code" */))
const _39acbd0e = () => interopDefault(import('../pages/more-info/index.vue' /* webpackChunkName: "pages/more-info/index" */))
const _d0168fe6 = () => interopDefault(import('../pages/more-info/index/contact/index.vue' /* webpackChunkName: "pages/more-info/index/contact/index" */))
const _60919460 = () => interopDefault(import('../pages/more-info/index/privacy-policy/index.vue' /* webpackChunkName: "pages/more-info/index/privacy-policy/index" */))
const _e01c1072 = () => interopDefault(import('../pages/more-info/index/terms-conditions/index.vue' /* webpackChunkName: "pages/more-info/index/terms-conditions/index" */))
const _2a62cb63 = () => interopDefault(import('../pages/my-esims/index.vue' /* webpackChunkName: "pages/my-esims/index" */))
const _72b3fba6 = () => interopDefault(import('../pages/my-esims/index/index.vue' /* webpackChunkName: "pages/my-esims/index/index" */))
const _ac3e1284 = () => interopDefault(import('../pages/my-esims/index/archived.vue' /* webpackChunkName: "pages/my-esims/index/archived" */))
const _332c17d3 = () => interopDefault(import('../pages/partner-with-us/index.vue' /* webpackChunkName: "pages/partner-with-us/index" */))
const _0c00593c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _22d2509c = () => interopDefault(import('../pages/profile/index/airmoney/index.vue' /* webpackChunkName: "pages/profile/index/airmoney/index" */))
const _86ec1f3c = () => interopDefault(import('../pages/profile/index/change-email.vue' /* webpackChunkName: "pages/profile/index/change-email" */))
const _b1b71e76 = () => interopDefault(import('../pages/profile/index/change-password.vue' /* webpackChunkName: "pages/profile/index/change-password" */))
const _6c95d9b5 = () => interopDefault(import('../pages/profile/index/credit-cards/index.vue' /* webpackChunkName: "pages/profile/index/credit-cards/index" */))
const _6fba946a = () => interopDefault(import('../pages/profile/index/orders/index.vue' /* webpackChunkName: "pages/profile/index/orders/index" */))
const _3ca1578d = () => interopDefault(import('../pages/profile/index/organizations.vue' /* webpackChunkName: "pages/profile/index/organizations" */))
const _3d6af1c3 = () => interopDefault(import('../pages/profile/index/referral/index.vue' /* webpackChunkName: "pages/profile/index/referral/index" */))
const _3164c2c9 = () => interopDefault(import('../pages/profile/index/settings/index.vue' /* webpackChunkName: "pages/profile/index/settings/index" */))
const _3a2735c6 = () => interopDefault(import('../pages/profile/index/identity-authentication/default.vue' /* webpackChunkName: "pages/profile/index/identity-authentication/default" */))
const _43cf1690 = () => interopDefault(import('../pages/profile/index/identity-authentication/onetime.vue' /* webpackChunkName: "pages/profile/index/identity-authentication/onetime" */))
const _e886184c = () => interopDefault(import('../pages/profile/index/settings/delete-account-success.vue' /* webpackChunkName: "pages/profile/index/settings/delete-account-success" */))
const _5fc5f336 = () => interopDefault(import('../pages/profile/index/orders/_id/index.vue' /* webpackChunkName: "pages/profile/index/orders/_id/index" */))
const _00cbdc80 = () => interopDefault(import('../pages/referral/index.vue' /* webpackChunkName: "pages/referral/index" */))
const _129c24c8 = () => interopDefault(import('../pages/verify/index.vue' /* webpackChunkName: "pages/verify/index" */))
const _3a3661c2 = () => interopDefault(import('../pages/verify/index/index.vue' /* webpackChunkName: "pages/verify/index/index" */))
const _546c44dc = () => interopDefault(import('../pages/verify/index/activation/index.vue' /* webpackChunkName: "pages/verify/index/activation/index" */))
const _17937505 = () => interopDefault(import('../pages/verify/index/change-email/index.vue' /* webpackChunkName: "pages/verify/index/change-email/index" */))
const _a8ff02c2 = () => interopDefault(import('../pages/verify/index/reset/index.vue' /* webpackChunkName: "pages/verify/index/reset/index" */))
const _eacaed36 = () => interopDefault(import('../pages/verify/index/respass-1/index.vue' /* webpackChunkName: "pages/verify/index/respass-1/index" */))
const _1f7f3c26 = () => interopDefault(import('../pages/verify/index/respass-2/index.vue' /* webpackChunkName: "pages/verify/index/respass-2/index" */))
const _1993f812 = () => interopDefault(import('../pages/verify/index/respass-success/index.vue' /* webpackChunkName: "pages/verify/index/respass-success/index" */))
const _aec9ad62 = () => interopDefault(import('../pages/about-us/abraham-burak.vue' /* webpackChunkName: "pages/about-us/abraham-burak" */))
const _06fe1ae9 = () => interopDefault(import('../pages/about-us/ahmet-bahadir-ozdemir.vue' /* webpackChunkName: "pages/about-us/ahmet-bahadir-ozdemir" */))
const _0c7ff970 = () => interopDefault(import('../pages/about-us/founders/index.vue' /* webpackChunkName: "pages/about-us/founders/index" */))
const _23563732 = () => interopDefault(import('../pages/about-us/impact.vue' /* webpackChunkName: "pages/about-us/impact" */))
const _7e8f0238 = () => interopDefault(import('../pages/about-us/our-values.vue' /* webpackChunkName: "pages/about-us/our-values" */))
const _418bb65f = () => interopDefault(import('../pages/airalo-careers/job-vacancies/index.vue' /* webpackChunkName: "pages/airalo-careers/job-vacancies/index" */))
const _dd08bda4 = () => interopDefault(import('../pages/airalo-careers/working-at-airalo/index.vue' /* webpackChunkName: "pages/airalo-careers/working-at-airalo/index" */))
const _5e02c11c = () => interopDefault(import('../pages/esim-buy/fail.vue' /* webpackChunkName: "pages/esim-buy/fail" */))
const _656aaf9f = () => interopDefault(import('../pages/order/fail.vue' /* webpackChunkName: "pages/order/fail" */))
const _555c637a = () => interopDefault(import('../pages/partner-with-us/affiliate-partners.vue' /* webpackChunkName: "pages/partner-with-us/affiliate-partners" */))
const _78bc0c74 = () => interopDefault(import('../pages/partner-with-us/airalo-for-corporates.vue' /* webpackChunkName: "pages/partner-with-us/airalo-for-corporates" */))
const _91f4aae8 = () => interopDefault(import('../pages/partner-with-us/airalo-partner-hub.vue' /* webpackChunkName: "pages/partner-with-us/airalo-partner-hub" */))
const _3613590d = () => interopDefault(import('../pages/partner-with-us/api-partners.vue' /* webpackChunkName: "pages/partner-with-us/api-partners" */))
const _132cb1d5 = () => interopDefault(import('../pages/partner-with-us/distribution-partners.vue' /* webpackChunkName: "pages/partner-with-us/distribution-partners" */))
const _a2e680dc = () => interopDefault(import('../pages/referral/referral-terms-and-conditions.vue' /* webpackChunkName: "pages/referral/referral-terms-and-conditions" */))
const _8ec1938a = () => interopDefault(import('../pages/my-esims/detail/_id.vue' /* webpackChunkName: "pages/my-esims/detail/_id" */))
const _05548ebe = () => interopDefault(import('../pages/my-esims/detail/_id/installation/android/index.vue' /* webpackChunkName: "pages/my-esims/detail/_id/installation/android/index" */))
const _5e91e6a4 = () => interopDefault(import('../pages/my-esims/detail/_id/installation/android/index/manual.vue' /* webpackChunkName: "pages/my-esims/detail/_id/installation/android/index/manual" */))
const _0694fa7f = () => interopDefault(import('../pages/my-esims/detail/_id/installation/android/index/qr.vue' /* webpackChunkName: "pages/my-esims/detail/_id/installation/android/index/qr" */))
const _065cb03f = () => interopDefault(import('../pages/my-esims/detail/_id/installation/ios/index.vue' /* webpackChunkName: "pages/my-esims/detail/_id/installation/ios/index" */))
const _bbde7d74 = () => interopDefault(import('../pages/my-esims/detail/_id/installation/ios/index/manual.vue' /* webpackChunkName: "pages/my-esims/detail/_id/installation/ios/index/manual" */))
const _f46eb26e = () => interopDefault(import('../pages/my-esims/detail/_id/installation/ios/index/manual/step-by-step-guide.vue' /* webpackChunkName: "pages/my-esims/detail/_id/installation/ios/index/manual/step-by-step-guide" */))
const _ef4313be = () => interopDefault(import('../pages/my-esims/detail/_id/installation/ios/index/qr.vue' /* webpackChunkName: "pages/my-esims/detail/_id/installation/ios/index/qr" */))
const _4c88d60e = () => interopDefault(import('../pages/my-esims/detail/_id/installation/ios/index/qr/step-by-step-guide.vue' /* webpackChunkName: "pages/my-esims/detail/_id/installation/ios/index/qr/step-by-step-guide" */))
const _351581b6 = () => interopDefault(import('../pages/blog/_title.vue' /* webpackChunkName: "pages/blog/_title" */))
const _3bf3f830 = () => interopDefault(import('../pages/campaign-terms/_slug/index.vue' /* webpackChunkName: "pages/campaign-terms/_slug/index" */))
const _3cf9b919 = () => interopDefault(import('../pages/esim-buy/_id/index.vue' /* webpackChunkName: "pages/esim-buy/_id/index" */))
const _650badd6 = () => interopDefault(import('../pages/esim-buy/_id/index/topup/_topup.vue' /* webpackChunkName: "pages/esim-buy/_id/index/topup/_topup" */))
const _2661bd0c = () => interopDefault(import('../pages/order/_id/index.vue' /* webpackChunkName: "pages/order/_id/index" */))
const _ae31597a = () => interopDefault(import('../pages/esim-buy/_id/cancel.vue' /* webpackChunkName: "pages/esim-buy/_id/cancel" */))
const _c15c537c = () => interopDefault(import('../pages/esim-buy/_id/complete.vue' /* webpackChunkName: "pages/esim-buy/_id/complete" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _9b5ef47e,
    name: "about-us___en",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___en"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___en"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___en"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___en"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___en"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___en"
    }]
  }, {
    path: "/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___en"
  }, {
    path: "/ar",
    component: _2e728222,
    name: "index___ar",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ar",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ar"
      }]
    }]
  }, {
    path: "/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___en"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___en"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___en"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___en"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___en"
    }]
  }, {
    path: "/az",
    component: _2e728222,
    name: "index___az",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___az",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___az"
      }]
    }]
  }, {
    path: "/bg",
    component: _2e728222,
    name: "index___bg",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___bg",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___bg"
      }]
    }]
  }, {
    path: "/blog",
    component: _545b2785,
    name: "blog___en"
  }, {
    path: "/bs",
    component: _2e728222,
    name: "index___bs",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___bs",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___bs"
      }]
    }]
  }, {
    path: "/campaign",
    component: _18f690f0,
    name: "campaign___en",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___en"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___en"
    }]
  }, {
    path: "/cs",
    component: _2e728222,
    name: "index___cs",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___cs",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___cs"
      }]
    }]
  }, {
    path: "/da",
    component: _2e728222,
    name: "index___da",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___da",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___da"
      }]
    }]
  }, {
    path: "/de",
    component: _2e728222,
    name: "index___de",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___de",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___de"
      }]
    }]
  }, {
    path: "/el",
    component: _2e728222,
    name: "index___el",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___el",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___el"
      }]
    }]
  }, {
    path: "/es-419",
    component: _2e728222,
    name: "index___es-419",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___es-419",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___es-419"
      }]
    }]
  }, {
    path: "/es-ES",
    component: _2e728222,
    name: "index___es-ES",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___es-ES",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___es-ES"
      }]
    }]
  }, {
    path: "/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___en"
  }, {
    path: "/et",
    component: _2e728222,
    name: "index___et",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___et",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___et"
      }]
    }]
  }, {
    path: "/fa",
    component: _2e728222,
    name: "index___fa",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___fa",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___fa"
      }]
    }]
  }, {
    path: "/fi",
    component: _2e728222,
    name: "index___fi",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___fi",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___fi"
      }]
    }]
  }, {
    path: "/fil",
    component: _2e728222,
    name: "index___fil",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___fil",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___fil"
      }]
    }]
  }, {
    path: "/fr",
    component: _2e728222,
    name: "index___fr",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___fr",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___fr"
      }]
    }]
  }, {
    path: "/he",
    component: _2e728222,
    name: "index___he",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___he",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___he"
      }]
    }]
  }, {
    path: "/hi",
    component: _2e728222,
    name: "index___hi",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___hi",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___hi"
      }]
    }]
  }, {
    path: "/hr",
    component: _2e728222,
    name: "index___hr",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___hr",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___hr"
      }]
    }]
  }, {
    path: "/hu",
    component: _2e728222,
    name: "index___hu",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___hu",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___hu"
      }]
    }]
  }, {
    path: "/hy-AM",
    component: _2e728222,
    name: "index___hy-AM",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___hy-AM",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___hy-AM"
      }]
    }]
  }, {
    path: "/id",
    component: _2e728222,
    name: "index___id",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___id",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___id"
      }]
    }]
  }, {
    path: "/it",
    component: _2e728222,
    name: "index___it",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___it",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___it"
      }]
    }]
  }, {
    path: "/ja",
    component: _2e728222,
    name: "index___ja",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ja",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ja"
      }]
    }]
  }, {
    path: "/ka",
    component: _2e728222,
    name: "index___ka",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ka",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ka"
      }]
    }]
  }, {
    path: "/kk",
    component: _2e728222,
    name: "index___kk",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___kk",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___kk"
      }]
    }]
  }, {
    path: "/kmr",
    component: _2e728222,
    name: "index___kmr",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___kmr",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___kmr"
      }]
    }]
  }, {
    path: "/ko",
    component: _2e728222,
    name: "index___ko",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ko",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ko"
      }]
    }]
  }, {
    path: "/ky",
    component: _2e728222,
    name: "index___ky",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ky",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ky"
      }]
    }]
  }, {
    path: "/lt",
    component: _2e728222,
    name: "index___lt",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___lt",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___lt"
      }]
    }]
  }, {
    path: "/lv",
    component: _2e728222,
    name: "index___lv",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___lv",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___lv"
      }]
    }]
  }, {
    path: "/mk",
    component: _2e728222,
    name: "index___mk",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___mk",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___mk"
      }]
    }]
  }, {
    path: "/more-info",
    component: _39acbd0e,
    name: "more-info___en",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___en"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___en"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___en"
    }]
  }, {
    path: "/ms",
    component: _2e728222,
    name: "index___ms",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ms",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ms"
      }]
    }]
  }, {
    path: "/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___en"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___en"
    }]
  }, {
    path: "/nb",
    component: _2e728222,
    name: "index___nb",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___nb",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___nb"
      }]
    }]
  }, {
    path: "/nl",
    component: _2e728222,
    name: "index___nl",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___nl",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___nl"
      }]
    }]
  }, {
    path: "/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___en"
  }, {
    path: "/pl",
    component: _2e728222,
    name: "index___pl",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___pl",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___pl"
      }]
    }]
  }, {
    path: "/profile",
    component: _0c00593c,
    name: "profile___en",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___en"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___en"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___en"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___en"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___en"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___en"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___en"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___en"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___en"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___en"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___en"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___en"
    }]
  }, {
    path: "/pt-BR",
    component: _2e728222,
    name: "index___pt-BR",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___pt-BR",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___pt-BR"
      }]
    }]
  }, {
    path: "/pt-PT",
    component: _2e728222,
    name: "index___pt-PT",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___pt-PT",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___pt-PT"
      }]
    }]
  }, {
    path: "/referral",
    component: _00cbdc80,
    name: "referral___en"
  }, {
    path: "/ro",
    component: _2e728222,
    name: "index___ro",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ro",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ro"
      }]
    }]
  }, {
    path: "/ru",
    component: _2e728222,
    name: "index___ru",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ru",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ru"
      }]
    }]
  }, {
    path: "/sk",
    component: _2e728222,
    name: "index___sk",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___sk",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___sk"
      }]
    }]
  }, {
    path: "/sq",
    component: _2e728222,
    name: "index___sq",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___sq",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___sq"
      }]
    }]
  }, {
    path: "/sr-CS",
    component: _2e728222,
    name: "index___sr-CS",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___sr-CS",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___sr-CS"
      }]
    }]
  }, {
    path: "/sv-SE",
    component: _2e728222,
    name: "index___sv-SE",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___sv-SE",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___sv-SE"
      }]
    }]
  }, {
    path: "/th",
    component: _2e728222,
    name: "index___th",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___th",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___th"
      }]
    }]
  }, {
    path: "/tr",
    component: _2e728222,
    name: "index___tr",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___tr",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___tr"
      }]
    }]
  }, {
    path: "/uk",
    component: _2e728222,
    name: "index___uk",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___uk",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___uk"
      }]
    }]
  }, {
    path: "/ur-PK",
    component: _2e728222,
    name: "index___ur-PK",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___ur-PK",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___ur-PK"
      }]
    }]
  }, {
    path: "/uz",
    component: _2e728222,
    name: "index___uz",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___uz",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___uz"
      }]
    }]
  }, {
    path: "/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___en"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___en"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___en"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___en"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___en"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___en"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___en"
    }]
  }, {
    path: "/vi",
    component: _2e728222,
    name: "index___vi",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___vi",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___vi"
      }]
    }]
  }, {
    path: "/vls-BE",
    component: _2e728222,
    name: "index___vls-BE",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___vls-BE",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___vls-BE"
      }]
    }]
  }, {
    path: "/zh-CN",
    component: _2e728222,
    name: "index___zh-CN",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___zh-CN",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___zh-CN"
      }]
    }]
  }, {
    path: "/zh-TW",
    component: _2e728222,
    name: "index___zh-TW",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___zh-TW",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___zh-TW"
      }]
    }]
  }, {
    path: "/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___en"
  }, {
    path: "/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___en"
  }, {
    path: "/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___en"
  }, {
    path: "/about-us/impact",
    component: _23563732,
    name: "about-us-impact___en"
  }, {
    path: "/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___en"
  }, {
    path: "/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___en"
  }, {
    path: "/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___en"
  }, {
    path: "/ar/about-us",
    component: _9b5ef47e,
    name: "about-us___ar",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ar"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ar"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ar"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ar"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ar"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ar"
    }]
  }, {
    path: "/ar/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ar"
  }, {
    path: "/ar/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ar"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ar"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ar"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ar"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ar"
    }]
  }, {
    path: "/ar/blog",
    component: _545b2785,
    name: "blog___ar"
  }, {
    path: "/ar/campaign",
    component: _18f690f0,
    name: "campaign___ar",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ar"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ar"
    }]
  }, {
    path: "/ar/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ar"
  }, {
    path: "/ar/more-info",
    component: _39acbd0e,
    name: "more-info___ar",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ar"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ar"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ar"
    }]
  }, {
    path: "/ar/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ar"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ar"
    }]
  }, {
    path: "/ar/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ar"
  }, {
    path: "/ar/profile",
    component: _0c00593c,
    name: "profile___ar",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ar"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ar"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ar"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ar"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ar"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ar"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ar"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ar"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ar"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ar"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ar"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ar"
    }]
  }, {
    path: "/ar/referral",
    component: _00cbdc80,
    name: "referral___ar"
  }, {
    path: "/ar/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ar"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ar"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ar"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ar"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ar"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ar"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ar"
    }]
  }, {
    path: "/az/about-us",
    component: _9b5ef47e,
    name: "about-us___az",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___az"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___az"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___az"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___az"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___az"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___az"
    }]
  }, {
    path: "/az/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___az"
  }, {
    path: "/az/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___az"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___az"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___az"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___az"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___az"
    }]
  }, {
    path: "/az/blog",
    component: _545b2785,
    name: "blog___az"
  }, {
    path: "/az/campaign",
    component: _18f690f0,
    name: "campaign___az",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___az"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___az"
    }]
  }, {
    path: "/az/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___az"
  }, {
    path: "/az/more-info",
    component: _39acbd0e,
    name: "more-info___az",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___az"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___az"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___az"
    }]
  }, {
    path: "/az/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___az"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___az"
    }]
  }, {
    path: "/az/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___az"
  }, {
    path: "/az/profile",
    component: _0c00593c,
    name: "profile___az",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___az"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___az"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___az"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___az"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___az"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___az"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___az"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___az"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___az"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___az"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___az"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___az"
    }]
  }, {
    path: "/az/referral",
    component: _00cbdc80,
    name: "referral___az"
  }, {
    path: "/az/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___az"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___az"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___az"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___az"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___az"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___az"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___az"
    }]
  }, {
    path: "/bg/about-us",
    component: _9b5ef47e,
    name: "about-us___bg",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___bg"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___bg"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___bg"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___bg"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___bg"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___bg"
    }]
  }, {
    path: "/bg/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___bg"
  }, {
    path: "/bg/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___bg"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___bg"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___bg"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___bg"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___bg"
    }]
  }, {
    path: "/bg/blog",
    component: _545b2785,
    name: "blog___bg"
  }, {
    path: "/bg/campaign",
    component: _18f690f0,
    name: "campaign___bg",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___bg"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___bg"
    }]
  }, {
    path: "/bg/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___bg"
  }, {
    path: "/bg/more-info",
    component: _39acbd0e,
    name: "more-info___bg",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___bg"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___bg"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___bg"
    }]
  }, {
    path: "/bg/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___bg"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___bg"
    }]
  }, {
    path: "/bg/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___bg"
  }, {
    path: "/bg/profile",
    component: _0c00593c,
    name: "profile___bg",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___bg"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___bg"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___bg"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___bg"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___bg"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___bg"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___bg"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___bg"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___bg"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___bg"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___bg"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___bg"
    }]
  }, {
    path: "/bg/referral",
    component: _00cbdc80,
    name: "referral___bg"
  }, {
    path: "/bg/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___bg"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___bg"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___bg"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___bg"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___bg"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___bg"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___bg"
    }]
  }, {
    path: "/bs/about-us",
    component: _9b5ef47e,
    name: "about-us___bs",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___bs"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___bs"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___bs"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___bs"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___bs"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___bs"
    }]
  }, {
    path: "/bs/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___bs"
  }, {
    path: "/bs/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___bs"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___bs"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___bs"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___bs"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___bs"
    }]
  }, {
    path: "/bs/blog",
    component: _545b2785,
    name: "blog___bs"
  }, {
    path: "/bs/campaign",
    component: _18f690f0,
    name: "campaign___bs",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___bs"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___bs"
    }]
  }, {
    path: "/bs/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___bs"
  }, {
    path: "/bs/more-info",
    component: _39acbd0e,
    name: "more-info___bs",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___bs"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___bs"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___bs"
    }]
  }, {
    path: "/bs/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___bs"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___bs"
    }]
  }, {
    path: "/bs/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___bs"
  }, {
    path: "/bs/profile",
    component: _0c00593c,
    name: "profile___bs",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___bs"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___bs"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___bs"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___bs"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___bs"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___bs"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___bs"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___bs"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___bs"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___bs"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___bs"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___bs"
    }]
  }, {
    path: "/bs/referral",
    component: _00cbdc80,
    name: "referral___bs"
  }, {
    path: "/bs/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___bs"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___bs"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___bs"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___bs"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___bs"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___bs"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___bs"
    }]
  }, {
    path: "/cs/about-us",
    component: _9b5ef47e,
    name: "about-us___cs",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___cs"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___cs"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___cs"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___cs"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___cs"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___cs"
    }]
  }, {
    path: "/cs/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___cs"
  }, {
    path: "/cs/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___cs"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___cs"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___cs"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___cs"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___cs"
    }]
  }, {
    path: "/cs/blog",
    component: _545b2785,
    name: "blog___cs"
  }, {
    path: "/cs/campaign",
    component: _18f690f0,
    name: "campaign___cs",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___cs"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___cs"
    }]
  }, {
    path: "/cs/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___cs"
  }, {
    path: "/cs/more-info",
    component: _39acbd0e,
    name: "more-info___cs",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___cs"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___cs"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___cs"
    }]
  }, {
    path: "/cs/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___cs"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___cs"
    }]
  }, {
    path: "/cs/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___cs"
  }, {
    path: "/cs/profile",
    component: _0c00593c,
    name: "profile___cs",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___cs"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___cs"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___cs"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___cs"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___cs"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___cs"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___cs"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___cs"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___cs"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___cs"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___cs"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___cs"
    }]
  }, {
    path: "/cs/referral",
    component: _00cbdc80,
    name: "referral___cs"
  }, {
    path: "/cs/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___cs"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___cs"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___cs"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___cs"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___cs"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___cs"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___cs"
    }]
  }, {
    path: "/da/about-us",
    component: _9b5ef47e,
    name: "about-us___da",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___da"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___da"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___da"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___da"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___da"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___da"
    }]
  }, {
    path: "/da/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___da"
  }, {
    path: "/da/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___da"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___da"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___da"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___da"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___da"
    }]
  }, {
    path: "/da/blog",
    component: _545b2785,
    name: "blog___da"
  }, {
    path: "/da/campaign",
    component: _18f690f0,
    name: "campaign___da",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___da"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___da"
    }]
  }, {
    path: "/da/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___da"
  }, {
    path: "/da/more-info",
    component: _39acbd0e,
    name: "more-info___da",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___da"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___da"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___da"
    }]
  }, {
    path: "/da/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___da"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___da"
    }]
  }, {
    path: "/da/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___da"
  }, {
    path: "/da/profile",
    component: _0c00593c,
    name: "profile___da",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___da"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___da"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___da"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___da"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___da"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___da"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___da"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___da"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___da"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___da"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___da"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___da"
    }]
  }, {
    path: "/da/referral",
    component: _00cbdc80,
    name: "referral___da"
  }, {
    path: "/da/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___da"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___da"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___da"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___da"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___da"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___da"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___da"
    }]
  }, {
    path: "/de/about-us",
    component: _9b5ef47e,
    name: "about-us___de",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___de"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___de"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___de"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___de"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___de"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___de"
    }]
  }, {
    path: "/de/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___de"
  }, {
    path: "/de/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___de"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___de"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___de"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___de"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___de"
    }]
  }, {
    path: "/de/blog",
    component: _545b2785,
    name: "blog___de"
  }, {
    path: "/de/campaign",
    component: _18f690f0,
    name: "campaign___de",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___de"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___de"
    }]
  }, {
    path: "/de/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___de"
  }, {
    path: "/de/more-info",
    component: _39acbd0e,
    name: "more-info___de",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___de"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___de"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___de"
    }]
  }, {
    path: "/de/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___de"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___de"
    }]
  }, {
    path: "/de/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___de"
  }, {
    path: "/de/profile",
    component: _0c00593c,
    name: "profile___de",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___de"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___de"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___de"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___de"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___de"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___de"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___de"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___de"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___de"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___de"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___de"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___de"
    }]
  }, {
    path: "/de/referral",
    component: _00cbdc80,
    name: "referral___de"
  }, {
    path: "/de/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___de"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___de"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___de"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___de"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___de"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___de"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___de"
    }]
  }, {
    path: "/el/about-us",
    component: _9b5ef47e,
    name: "about-us___el",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___el"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___el"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___el"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___el"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___el"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___el"
    }]
  }, {
    path: "/el/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___el"
  }, {
    path: "/el/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___el"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___el"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___el"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___el"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___el"
    }]
  }, {
    path: "/el/blog",
    component: _545b2785,
    name: "blog___el"
  }, {
    path: "/el/campaign",
    component: _18f690f0,
    name: "campaign___el",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___el"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___el"
    }]
  }, {
    path: "/el/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___el"
  }, {
    path: "/el/more-info",
    component: _39acbd0e,
    name: "more-info___el",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___el"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___el"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___el"
    }]
  }, {
    path: "/el/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___el"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___el"
    }]
  }, {
    path: "/el/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___el"
  }, {
    path: "/el/profile",
    component: _0c00593c,
    name: "profile___el",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___el"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___el"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___el"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___el"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___el"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___el"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___el"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___el"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___el"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___el"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___el"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___el"
    }]
  }, {
    path: "/el/referral",
    component: _00cbdc80,
    name: "referral___el"
  }, {
    path: "/el/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___el"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___el"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___el"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___el"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___el"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___el"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___el"
    }]
  }, {
    path: "/es-419/about-us",
    component: _9b5ef47e,
    name: "about-us___es-419",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___es-419"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___es-419"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___es-419"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___es-419"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___es-419"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___es-419"
    }]
  }, {
    path: "/es-419/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___es-419"
  }, {
    path: "/es-419/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___es-419"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___es-419"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___es-419"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___es-419"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___es-419"
    }]
  }, {
    path: "/es-419/blog",
    component: _545b2785,
    name: "blog___es-419"
  }, {
    path: "/es-419/campaign",
    component: _18f690f0,
    name: "campaign___es-419",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___es-419"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___es-419"
    }]
  }, {
    path: "/es-419/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___es-419"
  }, {
    path: "/es-419/more-info",
    component: _39acbd0e,
    name: "more-info___es-419",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___es-419"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___es-419"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___es-419"
    }]
  }, {
    path: "/es-419/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___es-419"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___es-419"
    }]
  }, {
    path: "/es-419/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___es-419"
  }, {
    path: "/es-419/profile",
    component: _0c00593c,
    name: "profile___es-419",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___es-419"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___es-419"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___es-419"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___es-419"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___es-419"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___es-419"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___es-419"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___es-419"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___es-419"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___es-419"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___es-419"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___es-419"
    }]
  }, {
    path: "/es-419/referral",
    component: _00cbdc80,
    name: "referral___es-419"
  }, {
    path: "/es-419/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___es-419"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___es-419"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___es-419"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___es-419"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___es-419"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___es-419"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___es-419"
    }]
  }, {
    path: "/es-ES/about-us",
    component: _9b5ef47e,
    name: "about-us___es-ES",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___es-ES"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___es-ES"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___es-ES"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___es-ES"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___es-ES"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___es-ES"
    }]
  }, {
    path: "/es-ES/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___es-ES"
  }, {
    path: "/es-ES/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___es-ES"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___es-ES"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___es-ES"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___es-ES"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___es-ES"
    }]
  }, {
    path: "/es-ES/blog",
    component: _545b2785,
    name: "blog___es-ES"
  }, {
    path: "/es-ES/campaign",
    component: _18f690f0,
    name: "campaign___es-ES",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___es-ES"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___es-ES"
    }]
  }, {
    path: "/es-ES/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___es-ES"
  }, {
    path: "/es-ES/more-info",
    component: _39acbd0e,
    name: "more-info___es-ES",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___es-ES"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___es-ES"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___es-ES"
    }]
  }, {
    path: "/es-ES/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___es-ES"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___es-ES"
    }]
  }, {
    path: "/es-ES/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___es-ES"
  }, {
    path: "/es-ES/profile",
    component: _0c00593c,
    name: "profile___es-ES",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___es-ES"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___es-ES"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___es-ES"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___es-ES"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___es-ES"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___es-ES"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___es-ES"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___es-ES"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___es-ES"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___es-ES"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___es-ES"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___es-ES"
    }]
  }, {
    path: "/es-ES/referral",
    component: _00cbdc80,
    name: "referral___es-ES"
  }, {
    path: "/es-ES/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___es-ES"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___es-ES"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___es-ES"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___es-ES"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___es-ES"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___es-ES"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___es-ES"
    }]
  }, {
    path: "/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___en"
  }, {
    path: "/et/about-us",
    component: _9b5ef47e,
    name: "about-us___et",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___et"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___et"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___et"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___et"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___et"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___et"
    }]
  }, {
    path: "/et/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___et"
  }, {
    path: "/et/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___et"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___et"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___et"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___et"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___et"
    }]
  }, {
    path: "/et/blog",
    component: _545b2785,
    name: "blog___et"
  }, {
    path: "/et/campaign",
    component: _18f690f0,
    name: "campaign___et",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___et"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___et"
    }]
  }, {
    path: "/et/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___et"
  }, {
    path: "/et/more-info",
    component: _39acbd0e,
    name: "more-info___et",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___et"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___et"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___et"
    }]
  }, {
    path: "/et/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___et"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___et"
    }]
  }, {
    path: "/et/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___et"
  }, {
    path: "/et/profile",
    component: _0c00593c,
    name: "profile___et",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___et"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___et"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___et"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___et"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___et"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___et"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___et"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___et"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___et"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___et"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___et"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___et"
    }]
  }, {
    path: "/et/referral",
    component: _00cbdc80,
    name: "referral___et"
  }, {
    path: "/et/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___et"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___et"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___et"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___et"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___et"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___et"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___et"
    }]
  }, {
    path: "/fa/about-us",
    component: _9b5ef47e,
    name: "about-us___fa",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___fa"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___fa"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___fa"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___fa"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___fa"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___fa"
    }]
  }, {
    path: "/fa/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___fa"
  }, {
    path: "/fa/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___fa"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___fa"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___fa"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___fa"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___fa"
    }]
  }, {
    path: "/fa/blog",
    component: _545b2785,
    name: "blog___fa"
  }, {
    path: "/fa/campaign",
    component: _18f690f0,
    name: "campaign___fa",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___fa"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___fa"
    }]
  }, {
    path: "/fa/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___fa"
  }, {
    path: "/fa/more-info",
    component: _39acbd0e,
    name: "more-info___fa",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___fa"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___fa"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___fa"
    }]
  }, {
    path: "/fa/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___fa"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___fa"
    }]
  }, {
    path: "/fa/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___fa"
  }, {
    path: "/fa/profile",
    component: _0c00593c,
    name: "profile___fa",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___fa"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___fa"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___fa"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___fa"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___fa"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___fa"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___fa"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___fa"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___fa"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___fa"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___fa"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___fa"
    }]
  }, {
    path: "/fa/referral",
    component: _00cbdc80,
    name: "referral___fa"
  }, {
    path: "/fa/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___fa"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___fa"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___fa"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___fa"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___fa"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___fa"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___fa"
    }]
  }, {
    path: "/fi/about-us",
    component: _9b5ef47e,
    name: "about-us___fi",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___fi"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___fi"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___fi"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___fi"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___fi"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___fi"
    }]
  }, {
    path: "/fi/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___fi"
  }, {
    path: "/fi/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___fi"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___fi"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___fi"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___fi"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___fi"
    }]
  }, {
    path: "/fi/blog",
    component: _545b2785,
    name: "blog___fi"
  }, {
    path: "/fi/campaign",
    component: _18f690f0,
    name: "campaign___fi",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___fi"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___fi"
    }]
  }, {
    path: "/fi/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___fi"
  }, {
    path: "/fi/more-info",
    component: _39acbd0e,
    name: "more-info___fi",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___fi"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___fi"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___fi"
    }]
  }, {
    path: "/fi/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___fi"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___fi"
    }]
  }, {
    path: "/fi/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___fi"
  }, {
    path: "/fi/profile",
    component: _0c00593c,
    name: "profile___fi",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___fi"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___fi"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___fi"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___fi"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___fi"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___fi"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___fi"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___fi"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___fi"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___fi"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___fi"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___fi"
    }]
  }, {
    path: "/fi/referral",
    component: _00cbdc80,
    name: "referral___fi"
  }, {
    path: "/fi/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___fi"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___fi"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___fi"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___fi"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___fi"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___fi"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___fi"
    }]
  }, {
    path: "/fil/about-us",
    component: _9b5ef47e,
    name: "about-us___fil",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___fil"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___fil"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___fil"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___fil"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___fil"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___fil"
    }]
  }, {
    path: "/fil/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___fil"
  }, {
    path: "/fil/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___fil"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___fil"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___fil"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___fil"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___fil"
    }]
  }, {
    path: "/fil/blog",
    component: _545b2785,
    name: "blog___fil"
  }, {
    path: "/fil/campaign",
    component: _18f690f0,
    name: "campaign___fil",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___fil"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___fil"
    }]
  }, {
    path: "/fil/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___fil"
  }, {
    path: "/fil/more-info",
    component: _39acbd0e,
    name: "more-info___fil",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___fil"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___fil"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___fil"
    }]
  }, {
    path: "/fil/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___fil"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___fil"
    }]
  }, {
    path: "/fil/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___fil"
  }, {
    path: "/fil/profile",
    component: _0c00593c,
    name: "profile___fil",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___fil"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___fil"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___fil"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___fil"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___fil"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___fil"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___fil"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___fil"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___fil"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___fil"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___fil"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___fil"
    }]
  }, {
    path: "/fil/referral",
    component: _00cbdc80,
    name: "referral___fil"
  }, {
    path: "/fil/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___fil"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___fil"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___fil"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___fil"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___fil"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___fil"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___fil"
    }]
  }, {
    path: "/fr/about-us",
    component: _9b5ef47e,
    name: "about-us___fr",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___fr"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___fr"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___fr"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___fr"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___fr"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___fr"
    }]
  }, {
    path: "/fr/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___fr"
  }, {
    path: "/fr/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___fr"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___fr"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___fr"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___fr"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___fr"
    }]
  }, {
    path: "/fr/blog",
    component: _545b2785,
    name: "blog___fr"
  }, {
    path: "/fr/campaign",
    component: _18f690f0,
    name: "campaign___fr",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___fr"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___fr"
    }]
  }, {
    path: "/fr/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___fr"
  }, {
    path: "/fr/more-info",
    component: _39acbd0e,
    name: "more-info___fr",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___fr"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___fr"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___fr"
    }]
  }, {
    path: "/fr/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___fr"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___fr"
    }]
  }, {
    path: "/fr/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___fr"
  }, {
    path: "/fr/profile",
    component: _0c00593c,
    name: "profile___fr",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___fr"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___fr"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___fr"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___fr"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___fr"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___fr"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___fr"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___fr"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___fr"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___fr"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___fr"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___fr"
    }]
  }, {
    path: "/fr/referral",
    component: _00cbdc80,
    name: "referral___fr"
  }, {
    path: "/fr/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___fr"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___fr"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___fr"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___fr"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___fr"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___fr"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___fr"
    }]
  }, {
    path: "/he/about-us",
    component: _9b5ef47e,
    name: "about-us___he",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___he"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___he"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___he"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___he"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___he"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___he"
    }]
  }, {
    path: "/he/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___he"
  }, {
    path: "/he/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___he"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___he"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___he"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___he"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___he"
    }]
  }, {
    path: "/he/blog",
    component: _545b2785,
    name: "blog___he"
  }, {
    path: "/he/campaign",
    component: _18f690f0,
    name: "campaign___he",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___he"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___he"
    }]
  }, {
    path: "/he/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___he"
  }, {
    path: "/he/more-info",
    component: _39acbd0e,
    name: "more-info___he",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___he"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___he"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___he"
    }]
  }, {
    path: "/he/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___he"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___he"
    }]
  }, {
    path: "/he/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___he"
  }, {
    path: "/he/profile",
    component: _0c00593c,
    name: "profile___he",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___he"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___he"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___he"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___he"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___he"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___he"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___he"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___he"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___he"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___he"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___he"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___he"
    }]
  }, {
    path: "/he/referral",
    component: _00cbdc80,
    name: "referral___he"
  }, {
    path: "/he/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___he"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___he"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___he"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___he"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___he"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___he"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___he"
    }]
  }, {
    path: "/hi/about-us",
    component: _9b5ef47e,
    name: "about-us___hi",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___hi"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___hi"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___hi"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___hi"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___hi"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___hi"
    }]
  }, {
    path: "/hi/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___hi"
  }, {
    path: "/hi/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___hi"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___hi"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___hi"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___hi"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___hi"
    }]
  }, {
    path: "/hi/blog",
    component: _545b2785,
    name: "blog___hi"
  }, {
    path: "/hi/campaign",
    component: _18f690f0,
    name: "campaign___hi",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___hi"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___hi"
    }]
  }, {
    path: "/hi/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___hi"
  }, {
    path: "/hi/more-info",
    component: _39acbd0e,
    name: "more-info___hi",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___hi"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___hi"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___hi"
    }]
  }, {
    path: "/hi/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___hi"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___hi"
    }]
  }, {
    path: "/hi/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___hi"
  }, {
    path: "/hi/profile",
    component: _0c00593c,
    name: "profile___hi",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___hi"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___hi"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___hi"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___hi"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___hi"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___hi"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___hi"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___hi"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___hi"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___hi"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___hi"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___hi"
    }]
  }, {
    path: "/hi/referral",
    component: _00cbdc80,
    name: "referral___hi"
  }, {
    path: "/hi/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___hi"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___hi"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___hi"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___hi"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___hi"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___hi"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___hi"
    }]
  }, {
    path: "/hr/about-us",
    component: _9b5ef47e,
    name: "about-us___hr",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___hr"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___hr"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___hr"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___hr"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___hr"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___hr"
    }]
  }, {
    path: "/hr/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___hr"
  }, {
    path: "/hr/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___hr"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___hr"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___hr"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___hr"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___hr"
    }]
  }, {
    path: "/hr/blog",
    component: _545b2785,
    name: "blog___hr"
  }, {
    path: "/hr/campaign",
    component: _18f690f0,
    name: "campaign___hr",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___hr"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___hr"
    }]
  }, {
    path: "/hr/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___hr"
  }, {
    path: "/hr/more-info",
    component: _39acbd0e,
    name: "more-info___hr",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___hr"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___hr"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___hr"
    }]
  }, {
    path: "/hr/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___hr"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___hr"
    }]
  }, {
    path: "/hr/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___hr"
  }, {
    path: "/hr/profile",
    component: _0c00593c,
    name: "profile___hr",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___hr"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___hr"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___hr"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___hr"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___hr"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___hr"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___hr"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___hr"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___hr"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___hr"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___hr"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___hr"
    }]
  }, {
    path: "/hr/referral",
    component: _00cbdc80,
    name: "referral___hr"
  }, {
    path: "/hr/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___hr"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___hr"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___hr"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___hr"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___hr"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___hr"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___hr"
    }]
  }, {
    path: "/hu/about-us",
    component: _9b5ef47e,
    name: "about-us___hu",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___hu"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___hu"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___hu"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___hu"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___hu"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___hu"
    }]
  }, {
    path: "/hu/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___hu"
  }, {
    path: "/hu/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___hu"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___hu"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___hu"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___hu"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___hu"
    }]
  }, {
    path: "/hu/blog",
    component: _545b2785,
    name: "blog___hu"
  }, {
    path: "/hu/campaign",
    component: _18f690f0,
    name: "campaign___hu",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___hu"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___hu"
    }]
  }, {
    path: "/hu/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___hu"
  }, {
    path: "/hu/more-info",
    component: _39acbd0e,
    name: "more-info___hu",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___hu"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___hu"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___hu"
    }]
  }, {
    path: "/hu/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___hu"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___hu"
    }]
  }, {
    path: "/hu/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___hu"
  }, {
    path: "/hu/profile",
    component: _0c00593c,
    name: "profile___hu",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___hu"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___hu"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___hu"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___hu"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___hu"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___hu"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___hu"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___hu"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___hu"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___hu"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___hu"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___hu"
    }]
  }, {
    path: "/hu/referral",
    component: _00cbdc80,
    name: "referral___hu"
  }, {
    path: "/hu/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___hu"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___hu"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___hu"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___hu"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___hu"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___hu"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___hu"
    }]
  }, {
    path: "/hy-AM/about-us",
    component: _9b5ef47e,
    name: "about-us___hy-AM",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___hy-AM"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___hy-AM"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___hy-AM"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___hy-AM"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___hy-AM"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___hy-AM"
    }]
  }, {
    path: "/hy-AM/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___hy-AM"
  }, {
    path: "/hy-AM/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___hy-AM"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___hy-AM"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___hy-AM"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___hy-AM"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___hy-AM"
    }]
  }, {
    path: "/hy-AM/blog",
    component: _545b2785,
    name: "blog___hy-AM"
  }, {
    path: "/hy-AM/campaign",
    component: _18f690f0,
    name: "campaign___hy-AM",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___hy-AM"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___hy-AM"
    }]
  }, {
    path: "/hy-AM/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___hy-AM"
  }, {
    path: "/hy-AM/more-info",
    component: _39acbd0e,
    name: "more-info___hy-AM",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___hy-AM"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___hy-AM"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___hy-AM"
    }]
  }, {
    path: "/hy-AM/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___hy-AM"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___hy-AM"
    }]
  }, {
    path: "/hy-AM/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___hy-AM"
  }, {
    path: "/hy-AM/profile",
    component: _0c00593c,
    name: "profile___hy-AM",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___hy-AM"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___hy-AM"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___hy-AM"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___hy-AM"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___hy-AM"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___hy-AM"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___hy-AM"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___hy-AM"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___hy-AM"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___hy-AM"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___hy-AM"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___hy-AM"
    }]
  }, {
    path: "/hy-AM/referral",
    component: _00cbdc80,
    name: "referral___hy-AM"
  }, {
    path: "/hy-AM/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___hy-AM"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___hy-AM"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___hy-AM"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___hy-AM"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___hy-AM"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___hy-AM"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___hy-AM"
    }]
  }, {
    path: "/id/about-us",
    component: _9b5ef47e,
    name: "about-us___id",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___id"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___id"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___id"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___id"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___id"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___id"
    }]
  }, {
    path: "/id/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___id"
  }, {
    path: "/id/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___id"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___id"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___id"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___id"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___id"
    }]
  }, {
    path: "/id/blog",
    component: _545b2785,
    name: "blog___id"
  }, {
    path: "/id/campaign",
    component: _18f690f0,
    name: "campaign___id",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___id"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___id"
    }]
  }, {
    path: "/id/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___id"
  }, {
    path: "/id/more-info",
    component: _39acbd0e,
    name: "more-info___id",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___id"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___id"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___id"
    }]
  }, {
    path: "/id/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___id"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___id"
    }]
  }, {
    path: "/id/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___id"
  }, {
    path: "/id/profile",
    component: _0c00593c,
    name: "profile___id",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___id"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___id"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___id"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___id"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___id"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___id"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___id"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___id"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___id"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___id"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___id"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___id"
    }]
  }, {
    path: "/id/referral",
    component: _00cbdc80,
    name: "referral___id"
  }, {
    path: "/id/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___id"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___id"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___id"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___id"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___id"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___id"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___id"
    }]
  }, {
    path: "/it/about-us",
    component: _9b5ef47e,
    name: "about-us___it",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___it"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___it"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___it"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___it"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___it"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___it"
    }]
  }, {
    path: "/it/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___it"
  }, {
    path: "/it/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___it"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___it"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___it"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___it"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___it"
    }]
  }, {
    path: "/it/blog",
    component: _545b2785,
    name: "blog___it"
  }, {
    path: "/it/campaign",
    component: _18f690f0,
    name: "campaign___it",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___it"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___it"
    }]
  }, {
    path: "/it/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___it"
  }, {
    path: "/it/more-info",
    component: _39acbd0e,
    name: "more-info___it",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___it"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___it"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___it"
    }]
  }, {
    path: "/it/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___it"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___it"
    }]
  }, {
    path: "/it/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___it"
  }, {
    path: "/it/profile",
    component: _0c00593c,
    name: "profile___it",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___it"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___it"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___it"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___it"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___it"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___it"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___it"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___it"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___it"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___it"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___it"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___it"
    }]
  }, {
    path: "/it/referral",
    component: _00cbdc80,
    name: "referral___it"
  }, {
    path: "/it/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___it"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___it"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___it"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___it"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___it"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___it"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___it"
    }]
  }, {
    path: "/ja/about-us",
    component: _9b5ef47e,
    name: "about-us___ja",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ja"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ja"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ja"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ja"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ja"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ja"
    }]
  }, {
    path: "/ja/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ja"
  }, {
    path: "/ja/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ja"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ja"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ja"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ja"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ja"
    }]
  }, {
    path: "/ja/blog",
    component: _545b2785,
    name: "blog___ja"
  }, {
    path: "/ja/campaign",
    component: _18f690f0,
    name: "campaign___ja",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ja"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ja"
    }]
  }, {
    path: "/ja/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ja"
  }, {
    path: "/ja/more-info",
    component: _39acbd0e,
    name: "more-info___ja",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ja"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ja"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ja"
    }]
  }, {
    path: "/ja/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ja"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ja"
    }]
  }, {
    path: "/ja/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ja"
  }, {
    path: "/ja/profile",
    component: _0c00593c,
    name: "profile___ja",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ja"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ja"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ja"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ja"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ja"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ja"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ja"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ja"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ja"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ja"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ja"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ja"
    }]
  }, {
    path: "/ja/referral",
    component: _00cbdc80,
    name: "referral___ja"
  }, {
    path: "/ja/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ja"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ja"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ja"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ja"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ja"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ja"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ja"
    }]
  }, {
    path: "/ka/about-us",
    component: _9b5ef47e,
    name: "about-us___ka",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ka"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ka"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ka"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ka"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ka"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ka"
    }]
  }, {
    path: "/ka/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ka"
  }, {
    path: "/ka/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ka"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ka"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ka"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ka"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ka"
    }]
  }, {
    path: "/ka/blog",
    component: _545b2785,
    name: "blog___ka"
  }, {
    path: "/ka/campaign",
    component: _18f690f0,
    name: "campaign___ka",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ka"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ka"
    }]
  }, {
    path: "/ka/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ka"
  }, {
    path: "/ka/more-info",
    component: _39acbd0e,
    name: "more-info___ka",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ka"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ka"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ka"
    }]
  }, {
    path: "/ka/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ka"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ka"
    }]
  }, {
    path: "/ka/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ka"
  }, {
    path: "/ka/profile",
    component: _0c00593c,
    name: "profile___ka",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ka"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ka"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ka"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ka"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ka"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ka"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ka"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ka"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ka"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ka"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ka"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ka"
    }]
  }, {
    path: "/ka/referral",
    component: _00cbdc80,
    name: "referral___ka"
  }, {
    path: "/ka/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ka"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ka"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ka"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ka"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ka"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ka"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ka"
    }]
  }, {
    path: "/kk/about-us",
    component: _9b5ef47e,
    name: "about-us___kk",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___kk"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___kk"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___kk"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___kk"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___kk"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___kk"
    }]
  }, {
    path: "/kk/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___kk"
  }, {
    path: "/kk/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___kk"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___kk"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___kk"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___kk"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___kk"
    }]
  }, {
    path: "/kk/blog",
    component: _545b2785,
    name: "blog___kk"
  }, {
    path: "/kk/campaign",
    component: _18f690f0,
    name: "campaign___kk",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___kk"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___kk"
    }]
  }, {
    path: "/kk/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___kk"
  }, {
    path: "/kk/more-info",
    component: _39acbd0e,
    name: "more-info___kk",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___kk"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___kk"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___kk"
    }]
  }, {
    path: "/kk/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___kk"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___kk"
    }]
  }, {
    path: "/kk/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___kk"
  }, {
    path: "/kk/profile",
    component: _0c00593c,
    name: "profile___kk",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___kk"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___kk"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___kk"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___kk"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___kk"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___kk"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___kk"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___kk"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___kk"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___kk"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___kk"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___kk"
    }]
  }, {
    path: "/kk/referral",
    component: _00cbdc80,
    name: "referral___kk"
  }, {
    path: "/kk/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___kk"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___kk"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___kk"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___kk"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___kk"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___kk"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___kk"
    }]
  }, {
    path: "/kmr/about-us",
    component: _9b5ef47e,
    name: "about-us___kmr",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___kmr"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___kmr"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___kmr"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___kmr"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___kmr"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___kmr"
    }]
  }, {
    path: "/kmr/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___kmr"
  }, {
    path: "/kmr/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___kmr"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___kmr"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___kmr"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___kmr"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___kmr"
    }]
  }, {
    path: "/kmr/blog",
    component: _545b2785,
    name: "blog___kmr"
  }, {
    path: "/kmr/campaign",
    component: _18f690f0,
    name: "campaign___kmr",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___kmr"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___kmr"
    }]
  }, {
    path: "/kmr/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___kmr"
  }, {
    path: "/kmr/more-info",
    component: _39acbd0e,
    name: "more-info___kmr",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___kmr"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___kmr"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___kmr"
    }]
  }, {
    path: "/kmr/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___kmr"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___kmr"
    }]
  }, {
    path: "/kmr/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___kmr"
  }, {
    path: "/kmr/profile",
    component: _0c00593c,
    name: "profile___kmr",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___kmr"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___kmr"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___kmr"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___kmr"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___kmr"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___kmr"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___kmr"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___kmr"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___kmr"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___kmr"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___kmr"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___kmr"
    }]
  }, {
    path: "/kmr/referral",
    component: _00cbdc80,
    name: "referral___kmr"
  }, {
    path: "/kmr/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___kmr"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___kmr"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___kmr"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___kmr"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___kmr"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___kmr"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___kmr"
    }]
  }, {
    path: "/ko/about-us",
    component: _9b5ef47e,
    name: "about-us___ko",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ko"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ko"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ko"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ko"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ko"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ko"
    }]
  }, {
    path: "/ko/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ko"
  }, {
    path: "/ko/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ko"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ko"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ko"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ko"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ko"
    }]
  }, {
    path: "/ko/blog",
    component: _545b2785,
    name: "blog___ko"
  }, {
    path: "/ko/campaign",
    component: _18f690f0,
    name: "campaign___ko",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ko"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ko"
    }]
  }, {
    path: "/ko/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ko"
  }, {
    path: "/ko/more-info",
    component: _39acbd0e,
    name: "more-info___ko",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ko"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ko"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ko"
    }]
  }, {
    path: "/ko/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ko"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ko"
    }]
  }, {
    path: "/ko/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ko"
  }, {
    path: "/ko/profile",
    component: _0c00593c,
    name: "profile___ko",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ko"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ko"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ko"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ko"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ko"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ko"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ko"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ko"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ko"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ko"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ko"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ko"
    }]
  }, {
    path: "/ko/referral",
    component: _00cbdc80,
    name: "referral___ko"
  }, {
    path: "/ko/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ko"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ko"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ko"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ko"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ko"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ko"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ko"
    }]
  }, {
    path: "/ky/about-us",
    component: _9b5ef47e,
    name: "about-us___ky",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ky"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ky"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ky"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ky"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ky"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ky"
    }]
  }, {
    path: "/ky/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ky"
  }, {
    path: "/ky/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ky"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ky"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ky"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ky"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ky"
    }]
  }, {
    path: "/ky/blog",
    component: _545b2785,
    name: "blog___ky"
  }, {
    path: "/ky/campaign",
    component: _18f690f0,
    name: "campaign___ky",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ky"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ky"
    }]
  }, {
    path: "/ky/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ky"
  }, {
    path: "/ky/more-info",
    component: _39acbd0e,
    name: "more-info___ky",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ky"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ky"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ky"
    }]
  }, {
    path: "/ky/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ky"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ky"
    }]
  }, {
    path: "/ky/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ky"
  }, {
    path: "/ky/profile",
    component: _0c00593c,
    name: "profile___ky",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ky"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ky"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ky"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ky"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ky"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ky"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ky"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ky"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ky"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ky"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ky"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ky"
    }]
  }, {
    path: "/ky/referral",
    component: _00cbdc80,
    name: "referral___ky"
  }, {
    path: "/ky/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ky"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ky"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ky"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ky"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ky"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ky"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ky"
    }]
  }, {
    path: "/lt/about-us",
    component: _9b5ef47e,
    name: "about-us___lt",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___lt"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___lt"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___lt"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___lt"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___lt"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___lt"
    }]
  }, {
    path: "/lt/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___lt"
  }, {
    path: "/lt/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___lt"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___lt"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___lt"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___lt"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___lt"
    }]
  }, {
    path: "/lt/blog",
    component: _545b2785,
    name: "blog___lt"
  }, {
    path: "/lt/campaign",
    component: _18f690f0,
    name: "campaign___lt",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___lt"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___lt"
    }]
  }, {
    path: "/lt/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___lt"
  }, {
    path: "/lt/more-info",
    component: _39acbd0e,
    name: "more-info___lt",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___lt"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___lt"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___lt"
    }]
  }, {
    path: "/lt/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___lt"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___lt"
    }]
  }, {
    path: "/lt/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___lt"
  }, {
    path: "/lt/profile",
    component: _0c00593c,
    name: "profile___lt",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___lt"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___lt"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___lt"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___lt"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___lt"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___lt"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___lt"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___lt"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___lt"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___lt"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___lt"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___lt"
    }]
  }, {
    path: "/lt/referral",
    component: _00cbdc80,
    name: "referral___lt"
  }, {
    path: "/lt/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___lt"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___lt"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___lt"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___lt"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___lt"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___lt"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___lt"
    }]
  }, {
    path: "/lv/about-us",
    component: _9b5ef47e,
    name: "about-us___lv",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___lv"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___lv"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___lv"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___lv"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___lv"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___lv"
    }]
  }, {
    path: "/lv/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___lv"
  }, {
    path: "/lv/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___lv"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___lv"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___lv"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___lv"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___lv"
    }]
  }, {
    path: "/lv/blog",
    component: _545b2785,
    name: "blog___lv"
  }, {
    path: "/lv/campaign",
    component: _18f690f0,
    name: "campaign___lv",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___lv"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___lv"
    }]
  }, {
    path: "/lv/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___lv"
  }, {
    path: "/lv/more-info",
    component: _39acbd0e,
    name: "more-info___lv",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___lv"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___lv"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___lv"
    }]
  }, {
    path: "/lv/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___lv"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___lv"
    }]
  }, {
    path: "/lv/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___lv"
  }, {
    path: "/lv/profile",
    component: _0c00593c,
    name: "profile___lv",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___lv"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___lv"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___lv"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___lv"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___lv"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___lv"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___lv"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___lv"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___lv"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___lv"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___lv"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___lv"
    }]
  }, {
    path: "/lv/referral",
    component: _00cbdc80,
    name: "referral___lv"
  }, {
    path: "/lv/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___lv"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___lv"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___lv"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___lv"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___lv"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___lv"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___lv"
    }]
  }, {
    path: "/mk/about-us",
    component: _9b5ef47e,
    name: "about-us___mk",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___mk"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___mk"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___mk"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___mk"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___mk"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___mk"
    }]
  }, {
    path: "/mk/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___mk"
  }, {
    path: "/mk/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___mk"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___mk"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___mk"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___mk"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___mk"
    }]
  }, {
    path: "/mk/blog",
    component: _545b2785,
    name: "blog___mk"
  }, {
    path: "/mk/campaign",
    component: _18f690f0,
    name: "campaign___mk",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___mk"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___mk"
    }]
  }, {
    path: "/mk/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___mk"
  }, {
    path: "/mk/more-info",
    component: _39acbd0e,
    name: "more-info___mk",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___mk"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___mk"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___mk"
    }]
  }, {
    path: "/mk/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___mk"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___mk"
    }]
  }, {
    path: "/mk/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___mk"
  }, {
    path: "/mk/profile",
    component: _0c00593c,
    name: "profile___mk",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___mk"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___mk"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___mk"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___mk"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___mk"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___mk"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___mk"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___mk"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___mk"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___mk"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___mk"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___mk"
    }]
  }, {
    path: "/mk/referral",
    component: _00cbdc80,
    name: "referral___mk"
  }, {
    path: "/mk/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___mk"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___mk"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___mk"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___mk"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___mk"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___mk"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___mk"
    }]
  }, {
    path: "/ms/about-us",
    component: _9b5ef47e,
    name: "about-us___ms",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ms"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ms"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ms"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ms"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ms"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ms"
    }]
  }, {
    path: "/ms/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ms"
  }, {
    path: "/ms/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ms"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ms"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ms"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ms"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ms"
    }]
  }, {
    path: "/ms/blog",
    component: _545b2785,
    name: "blog___ms"
  }, {
    path: "/ms/campaign",
    component: _18f690f0,
    name: "campaign___ms",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ms"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ms"
    }]
  }, {
    path: "/ms/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ms"
  }, {
    path: "/ms/more-info",
    component: _39acbd0e,
    name: "more-info___ms",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ms"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ms"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ms"
    }]
  }, {
    path: "/ms/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ms"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ms"
    }]
  }, {
    path: "/ms/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ms"
  }, {
    path: "/ms/profile",
    component: _0c00593c,
    name: "profile___ms",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ms"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ms"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ms"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ms"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ms"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ms"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ms"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ms"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ms"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ms"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ms"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ms"
    }]
  }, {
    path: "/ms/referral",
    component: _00cbdc80,
    name: "referral___ms"
  }, {
    path: "/ms/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ms"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ms"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ms"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ms"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ms"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ms"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ms"
    }]
  }, {
    path: "/nb/about-us",
    component: _9b5ef47e,
    name: "about-us___nb",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___nb"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___nb"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___nb"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___nb"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___nb"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___nb"
    }]
  }, {
    path: "/nb/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___nb"
  }, {
    path: "/nb/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___nb"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___nb"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___nb"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___nb"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___nb"
    }]
  }, {
    path: "/nb/blog",
    component: _545b2785,
    name: "blog___nb"
  }, {
    path: "/nb/campaign",
    component: _18f690f0,
    name: "campaign___nb",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___nb"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___nb"
    }]
  }, {
    path: "/nb/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___nb"
  }, {
    path: "/nb/more-info",
    component: _39acbd0e,
    name: "more-info___nb",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___nb"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___nb"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___nb"
    }]
  }, {
    path: "/nb/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___nb"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___nb"
    }]
  }, {
    path: "/nb/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___nb"
  }, {
    path: "/nb/profile",
    component: _0c00593c,
    name: "profile___nb",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___nb"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___nb"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___nb"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___nb"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___nb"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___nb"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___nb"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___nb"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___nb"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___nb"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___nb"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___nb"
    }]
  }, {
    path: "/nb/referral",
    component: _00cbdc80,
    name: "referral___nb"
  }, {
    path: "/nb/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___nb"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___nb"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___nb"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___nb"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___nb"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___nb"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___nb"
    }]
  }, {
    path: "/nl/about-us",
    component: _9b5ef47e,
    name: "about-us___nl",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___nl"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___nl"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___nl"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___nl"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___nl"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___nl"
    }]
  }, {
    path: "/nl/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___nl"
  }, {
    path: "/nl/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___nl"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___nl"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___nl"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___nl"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___nl"
    }]
  }, {
    path: "/nl/blog",
    component: _545b2785,
    name: "blog___nl"
  }, {
    path: "/nl/campaign",
    component: _18f690f0,
    name: "campaign___nl",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___nl"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___nl"
    }]
  }, {
    path: "/nl/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___nl"
  }, {
    path: "/nl/more-info",
    component: _39acbd0e,
    name: "more-info___nl",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___nl"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___nl"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___nl"
    }]
  }, {
    path: "/nl/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___nl"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___nl"
    }]
  }, {
    path: "/nl/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___nl"
  }, {
    path: "/nl/profile",
    component: _0c00593c,
    name: "profile___nl",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___nl"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___nl"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___nl"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___nl"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___nl"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___nl"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___nl"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___nl"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___nl"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___nl"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___nl"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___nl"
    }]
  }, {
    path: "/nl/referral",
    component: _00cbdc80,
    name: "referral___nl"
  }, {
    path: "/nl/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___nl"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___nl"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___nl"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___nl"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___nl"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___nl"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___nl"
    }]
  }, {
    path: "/order/fail",
    component: _656aaf9f,
    name: "order-fail___en"
  }, {
    path: "/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___en"
  }, {
    path: "/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___en"
  }, {
    path: "/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___en"
  }, {
    path: "/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___en"
  }, {
    path: "/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___en"
  }, {
    path: "/pl/about-us",
    component: _9b5ef47e,
    name: "about-us___pl",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___pl"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___pl"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___pl"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___pl"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___pl"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___pl"
    }]
  }, {
    path: "/pl/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___pl"
  }, {
    path: "/pl/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___pl"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___pl"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___pl"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___pl"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___pl"
    }]
  }, {
    path: "/pl/blog",
    component: _545b2785,
    name: "blog___pl"
  }, {
    path: "/pl/campaign",
    component: _18f690f0,
    name: "campaign___pl",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___pl"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___pl"
    }]
  }, {
    path: "/pl/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___pl"
  }, {
    path: "/pl/more-info",
    component: _39acbd0e,
    name: "more-info___pl",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___pl"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___pl"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___pl"
    }]
  }, {
    path: "/pl/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___pl"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___pl"
    }]
  }, {
    path: "/pl/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___pl"
  }, {
    path: "/pl/profile",
    component: _0c00593c,
    name: "profile___pl",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___pl"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___pl"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___pl"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___pl"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___pl"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___pl"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___pl"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___pl"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___pl"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___pl"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___pl"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___pl"
    }]
  }, {
    path: "/pl/referral",
    component: _00cbdc80,
    name: "referral___pl"
  }, {
    path: "/pl/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___pl"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___pl"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___pl"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___pl"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___pl"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___pl"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___pl"
    }]
  }, {
    path: "/pt-BR/about-us",
    component: _9b5ef47e,
    name: "about-us___pt-BR",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___pt-BR"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___pt-BR"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___pt-BR"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___pt-BR"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___pt-BR"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___pt-BR"
    }]
  }, {
    path: "/pt-BR/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___pt-BR"
  }, {
    path: "/pt-BR/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___pt-BR"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___pt-BR"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___pt-BR"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___pt-BR"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___pt-BR"
    }]
  }, {
    path: "/pt-BR/blog",
    component: _545b2785,
    name: "blog___pt-BR"
  }, {
    path: "/pt-BR/campaign",
    component: _18f690f0,
    name: "campaign___pt-BR",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___pt-BR"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___pt-BR"
    }]
  }, {
    path: "/pt-BR/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___pt-BR"
  }, {
    path: "/pt-BR/more-info",
    component: _39acbd0e,
    name: "more-info___pt-BR",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___pt-BR"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___pt-BR"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___pt-BR"
    }]
  }, {
    path: "/pt-BR/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___pt-BR"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___pt-BR"
    }]
  }, {
    path: "/pt-BR/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___pt-BR"
  }, {
    path: "/pt-BR/profile",
    component: _0c00593c,
    name: "profile___pt-BR",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___pt-BR"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___pt-BR"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___pt-BR"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___pt-BR"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___pt-BR"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___pt-BR"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___pt-BR"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___pt-BR"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___pt-BR"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___pt-BR"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___pt-BR"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___pt-BR"
    }]
  }, {
    path: "/pt-BR/referral",
    component: _00cbdc80,
    name: "referral___pt-BR"
  }, {
    path: "/pt-BR/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___pt-BR"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___pt-BR"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___pt-BR"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___pt-BR"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___pt-BR"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___pt-BR"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___pt-BR"
    }]
  }, {
    path: "/pt-PT/about-us",
    component: _9b5ef47e,
    name: "about-us___pt-PT",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___pt-PT"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___pt-PT"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___pt-PT"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___pt-PT"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___pt-PT"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___pt-PT"
    }]
  }, {
    path: "/pt-PT/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___pt-PT"
  }, {
    path: "/pt-PT/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___pt-PT"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___pt-PT"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___pt-PT"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___pt-PT"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___pt-PT"
    }]
  }, {
    path: "/pt-PT/blog",
    component: _545b2785,
    name: "blog___pt-PT"
  }, {
    path: "/pt-PT/campaign",
    component: _18f690f0,
    name: "campaign___pt-PT",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___pt-PT"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___pt-PT"
    }]
  }, {
    path: "/pt-PT/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___pt-PT"
  }, {
    path: "/pt-PT/more-info",
    component: _39acbd0e,
    name: "more-info___pt-PT",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___pt-PT"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___pt-PT"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___pt-PT"
    }]
  }, {
    path: "/pt-PT/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___pt-PT"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___pt-PT"
    }]
  }, {
    path: "/pt-PT/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___pt-PT"
  }, {
    path: "/pt-PT/profile",
    component: _0c00593c,
    name: "profile___pt-PT",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___pt-PT"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___pt-PT"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___pt-PT"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___pt-PT"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___pt-PT"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___pt-PT"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___pt-PT"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___pt-PT"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___pt-PT"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___pt-PT"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___pt-PT"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___pt-PT"
    }]
  }, {
    path: "/pt-PT/referral",
    component: _00cbdc80,
    name: "referral___pt-PT"
  }, {
    path: "/pt-PT/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___pt-PT"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___pt-PT"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___pt-PT"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___pt-PT"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___pt-PT"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___pt-PT"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___pt-PT"
    }]
  }, {
    path: "/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___en"
  }, {
    path: "/ro/about-us",
    component: _9b5ef47e,
    name: "about-us___ro",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ro"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ro"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ro"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ro"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ro"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ro"
    }]
  }, {
    path: "/ro/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ro"
  }, {
    path: "/ro/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ro"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ro"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ro"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ro"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ro"
    }]
  }, {
    path: "/ro/blog",
    component: _545b2785,
    name: "blog___ro"
  }, {
    path: "/ro/campaign",
    component: _18f690f0,
    name: "campaign___ro",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ro"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ro"
    }]
  }, {
    path: "/ro/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ro"
  }, {
    path: "/ro/more-info",
    component: _39acbd0e,
    name: "more-info___ro",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ro"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ro"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ro"
    }]
  }, {
    path: "/ro/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ro"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ro"
    }]
  }, {
    path: "/ro/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ro"
  }, {
    path: "/ro/profile",
    component: _0c00593c,
    name: "profile___ro",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ro"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ro"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ro"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ro"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ro"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ro"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ro"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ro"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ro"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ro"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ro"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ro"
    }]
  }, {
    path: "/ro/referral",
    component: _00cbdc80,
    name: "referral___ro"
  }, {
    path: "/ro/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ro"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ro"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ro"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ro"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ro"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ro"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ro"
    }]
  }, {
    path: "/ru/about-us",
    component: _9b5ef47e,
    name: "about-us___ru",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ru"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ru"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ru"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ru"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ru"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ru"
    }]
  }, {
    path: "/ru/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ru"
  }, {
    path: "/ru/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ru"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ru"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ru"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ru"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ru"
    }]
  }, {
    path: "/ru/blog",
    component: _545b2785,
    name: "blog___ru"
  }, {
    path: "/ru/campaign",
    component: _18f690f0,
    name: "campaign___ru",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ru"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ru"
    }]
  }, {
    path: "/ru/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ru"
  }, {
    path: "/ru/more-info",
    component: _39acbd0e,
    name: "more-info___ru",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ru"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ru"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ru"
    }]
  }, {
    path: "/ru/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ru"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ru"
    }]
  }, {
    path: "/ru/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ru"
  }, {
    path: "/ru/profile",
    component: _0c00593c,
    name: "profile___ru",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ru"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ru"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ru"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ru"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ru"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ru"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ru"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ru"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ru"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ru"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ru"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ru"
    }]
  }, {
    path: "/ru/referral",
    component: _00cbdc80,
    name: "referral___ru"
  }, {
    path: "/ru/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ru"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ru"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ru"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ru"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ru"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ru"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ru"
    }]
  }, {
    path: "/sk/about-us",
    component: _9b5ef47e,
    name: "about-us___sk",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___sk"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___sk"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___sk"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___sk"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___sk"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___sk"
    }]
  }, {
    path: "/sk/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___sk"
  }, {
    path: "/sk/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___sk"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___sk"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___sk"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___sk"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___sk"
    }]
  }, {
    path: "/sk/blog",
    component: _545b2785,
    name: "blog___sk"
  }, {
    path: "/sk/campaign",
    component: _18f690f0,
    name: "campaign___sk",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___sk"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___sk"
    }]
  }, {
    path: "/sk/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___sk"
  }, {
    path: "/sk/more-info",
    component: _39acbd0e,
    name: "more-info___sk",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___sk"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___sk"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___sk"
    }]
  }, {
    path: "/sk/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___sk"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___sk"
    }]
  }, {
    path: "/sk/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___sk"
  }, {
    path: "/sk/profile",
    component: _0c00593c,
    name: "profile___sk",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___sk"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___sk"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___sk"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___sk"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___sk"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___sk"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___sk"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___sk"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___sk"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___sk"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___sk"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___sk"
    }]
  }, {
    path: "/sk/referral",
    component: _00cbdc80,
    name: "referral___sk"
  }, {
    path: "/sk/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___sk"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___sk"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___sk"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___sk"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___sk"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___sk"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___sk"
    }]
  }, {
    path: "/sq/about-us",
    component: _9b5ef47e,
    name: "about-us___sq",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___sq"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___sq"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___sq"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___sq"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___sq"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___sq"
    }]
  }, {
    path: "/sq/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___sq"
  }, {
    path: "/sq/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___sq"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___sq"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___sq"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___sq"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___sq"
    }]
  }, {
    path: "/sq/blog",
    component: _545b2785,
    name: "blog___sq"
  }, {
    path: "/sq/campaign",
    component: _18f690f0,
    name: "campaign___sq",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___sq"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___sq"
    }]
  }, {
    path: "/sq/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___sq"
  }, {
    path: "/sq/more-info",
    component: _39acbd0e,
    name: "more-info___sq",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___sq"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___sq"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___sq"
    }]
  }, {
    path: "/sq/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___sq"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___sq"
    }]
  }, {
    path: "/sq/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___sq"
  }, {
    path: "/sq/profile",
    component: _0c00593c,
    name: "profile___sq",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___sq"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___sq"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___sq"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___sq"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___sq"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___sq"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___sq"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___sq"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___sq"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___sq"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___sq"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___sq"
    }]
  }, {
    path: "/sq/referral",
    component: _00cbdc80,
    name: "referral___sq"
  }, {
    path: "/sq/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___sq"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___sq"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___sq"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___sq"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___sq"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___sq"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___sq"
    }]
  }, {
    path: "/sr-CS/about-us",
    component: _9b5ef47e,
    name: "about-us___sr-CS",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___sr-CS"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___sr-CS"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___sr-CS"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___sr-CS"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___sr-CS"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___sr-CS"
    }]
  }, {
    path: "/sr-CS/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___sr-CS"
  }, {
    path: "/sr-CS/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___sr-CS"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___sr-CS"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___sr-CS"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___sr-CS"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___sr-CS"
    }]
  }, {
    path: "/sr-CS/blog",
    component: _545b2785,
    name: "blog___sr-CS"
  }, {
    path: "/sr-CS/campaign",
    component: _18f690f0,
    name: "campaign___sr-CS",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___sr-CS"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___sr-CS"
    }]
  }, {
    path: "/sr-CS/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___sr-CS"
  }, {
    path: "/sr-CS/more-info",
    component: _39acbd0e,
    name: "more-info___sr-CS",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___sr-CS"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___sr-CS"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___sr-CS"
    }]
  }, {
    path: "/sr-CS/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___sr-CS"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___sr-CS"
    }]
  }, {
    path: "/sr-CS/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___sr-CS"
  }, {
    path: "/sr-CS/profile",
    component: _0c00593c,
    name: "profile___sr-CS",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___sr-CS"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___sr-CS"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___sr-CS"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___sr-CS"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___sr-CS"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___sr-CS"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___sr-CS"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___sr-CS"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___sr-CS"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___sr-CS"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___sr-CS"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___sr-CS"
    }]
  }, {
    path: "/sr-CS/referral",
    component: _00cbdc80,
    name: "referral___sr-CS"
  }, {
    path: "/sr-CS/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___sr-CS"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___sr-CS"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___sr-CS"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___sr-CS"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___sr-CS"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___sr-CS"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___sr-CS"
    }]
  }, {
    path: "/sv-SE/about-us",
    component: _9b5ef47e,
    name: "about-us___sv-SE",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___sv-SE"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___sv-SE"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___sv-SE"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___sv-SE"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___sv-SE"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___sv-SE"
    }]
  }, {
    path: "/sv-SE/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___sv-SE"
  }, {
    path: "/sv-SE/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___sv-SE"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___sv-SE"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___sv-SE"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___sv-SE"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___sv-SE"
    }]
  }, {
    path: "/sv-SE/blog",
    component: _545b2785,
    name: "blog___sv-SE"
  }, {
    path: "/sv-SE/campaign",
    component: _18f690f0,
    name: "campaign___sv-SE",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___sv-SE"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___sv-SE"
    }]
  }, {
    path: "/sv-SE/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___sv-SE"
  }, {
    path: "/sv-SE/more-info",
    component: _39acbd0e,
    name: "more-info___sv-SE",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___sv-SE"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___sv-SE"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___sv-SE"
    }]
  }, {
    path: "/sv-SE/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___sv-SE"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___sv-SE"
    }]
  }, {
    path: "/sv-SE/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___sv-SE"
  }, {
    path: "/sv-SE/profile",
    component: _0c00593c,
    name: "profile___sv-SE",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___sv-SE"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___sv-SE"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___sv-SE"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___sv-SE"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___sv-SE"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___sv-SE"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___sv-SE"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___sv-SE"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___sv-SE"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___sv-SE"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___sv-SE"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___sv-SE"
    }]
  }, {
    path: "/sv-SE/referral",
    component: _00cbdc80,
    name: "referral___sv-SE"
  }, {
    path: "/sv-SE/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___sv-SE"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___sv-SE"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___sv-SE"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___sv-SE"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___sv-SE"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___sv-SE"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___sv-SE"
    }]
  }, {
    path: "/th/about-us",
    component: _9b5ef47e,
    name: "about-us___th",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___th"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___th"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___th"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___th"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___th"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___th"
    }]
  }, {
    path: "/th/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___th"
  }, {
    path: "/th/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___th"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___th"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___th"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___th"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___th"
    }]
  }, {
    path: "/th/blog",
    component: _545b2785,
    name: "blog___th"
  }, {
    path: "/th/campaign",
    component: _18f690f0,
    name: "campaign___th",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___th"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___th"
    }]
  }, {
    path: "/th/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___th"
  }, {
    path: "/th/more-info",
    component: _39acbd0e,
    name: "more-info___th",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___th"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___th"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___th"
    }]
  }, {
    path: "/th/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___th"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___th"
    }]
  }, {
    path: "/th/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___th"
  }, {
    path: "/th/profile",
    component: _0c00593c,
    name: "profile___th",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___th"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___th"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___th"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___th"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___th"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___th"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___th"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___th"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___th"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___th"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___th"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___th"
    }]
  }, {
    path: "/th/referral",
    component: _00cbdc80,
    name: "referral___th"
  }, {
    path: "/th/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___th"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___th"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___th"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___th"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___th"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___th"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___th"
    }]
  }, {
    path: "/tr/about-us",
    component: _9b5ef47e,
    name: "about-us___tr",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___tr"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___tr"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___tr"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___tr"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___tr"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___tr"
    }]
  }, {
    path: "/tr/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___tr"
  }, {
    path: "/tr/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___tr"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___tr"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___tr"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___tr"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___tr"
    }]
  }, {
    path: "/tr/blog",
    component: _545b2785,
    name: "blog___tr"
  }, {
    path: "/tr/campaign",
    component: _18f690f0,
    name: "campaign___tr",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___tr"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___tr"
    }]
  }, {
    path: "/tr/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___tr"
  }, {
    path: "/tr/more-info",
    component: _39acbd0e,
    name: "more-info___tr",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___tr"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___tr"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___tr"
    }]
  }, {
    path: "/tr/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___tr"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___tr"
    }]
  }, {
    path: "/tr/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___tr"
  }, {
    path: "/tr/profile",
    component: _0c00593c,
    name: "profile___tr",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___tr"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___tr"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___tr"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___tr"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___tr"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___tr"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___tr"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___tr"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___tr"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___tr"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___tr"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___tr"
    }]
  }, {
    path: "/tr/referral",
    component: _00cbdc80,
    name: "referral___tr"
  }, {
    path: "/tr/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___tr"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___tr"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___tr"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___tr"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___tr"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___tr"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___tr"
    }]
  }, {
    path: "/uk/about-us",
    component: _9b5ef47e,
    name: "about-us___uk",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___uk"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___uk"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___uk"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___uk"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___uk"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___uk"
    }]
  }, {
    path: "/uk/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___uk"
  }, {
    path: "/uk/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___uk"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___uk"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___uk"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___uk"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___uk"
    }]
  }, {
    path: "/uk/blog",
    component: _545b2785,
    name: "blog___uk"
  }, {
    path: "/uk/campaign",
    component: _18f690f0,
    name: "campaign___uk",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___uk"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___uk"
    }]
  }, {
    path: "/uk/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___uk"
  }, {
    path: "/uk/more-info",
    component: _39acbd0e,
    name: "more-info___uk",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___uk"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___uk"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___uk"
    }]
  }, {
    path: "/uk/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___uk"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___uk"
    }]
  }, {
    path: "/uk/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___uk"
  }, {
    path: "/uk/profile",
    component: _0c00593c,
    name: "profile___uk",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___uk"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___uk"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___uk"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___uk"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___uk"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___uk"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___uk"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___uk"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___uk"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___uk"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___uk"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___uk"
    }]
  }, {
    path: "/uk/referral",
    component: _00cbdc80,
    name: "referral___uk"
  }, {
    path: "/uk/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___uk"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___uk"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___uk"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___uk"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___uk"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___uk"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___uk"
    }]
  }, {
    path: "/ur-PK/about-us",
    component: _9b5ef47e,
    name: "about-us___ur-PK",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___ur-PK"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___ur-PK"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___ur-PK"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___ur-PK"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___ur-PK"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___ur-PK"
    }]
  }, {
    path: "/ur-PK/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___ur-PK"
  }, {
    path: "/ur-PK/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___ur-PK"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___ur-PK"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___ur-PK"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___ur-PK"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___ur-PK"
    }]
  }, {
    path: "/ur-PK/blog",
    component: _545b2785,
    name: "blog___ur-PK"
  }, {
    path: "/ur-PK/campaign",
    component: _18f690f0,
    name: "campaign___ur-PK",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___ur-PK"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___ur-PK"
    }]
  }, {
    path: "/ur-PK/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___ur-PK"
  }, {
    path: "/ur-PK/more-info",
    component: _39acbd0e,
    name: "more-info___ur-PK",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___ur-PK"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___ur-PK"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___ur-PK"
    }]
  }, {
    path: "/ur-PK/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___ur-PK"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___ur-PK"
    }]
  }, {
    path: "/ur-PK/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___ur-PK"
  }, {
    path: "/ur-PK/profile",
    component: _0c00593c,
    name: "profile___ur-PK",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___ur-PK"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___ur-PK"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___ur-PK"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___ur-PK"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___ur-PK"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___ur-PK"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___ur-PK"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___ur-PK"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___ur-PK"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___ur-PK"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___ur-PK"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___ur-PK"
    }]
  }, {
    path: "/ur-PK/referral",
    component: _00cbdc80,
    name: "referral___ur-PK"
  }, {
    path: "/ur-PK/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___ur-PK"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___ur-PK"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___ur-PK"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___ur-PK"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___ur-PK"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___ur-PK"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___ur-PK"
    }]
  }, {
    path: "/uz/about-us",
    component: _9b5ef47e,
    name: "about-us___uz",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___uz"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___uz"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___uz"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___uz"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___uz"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___uz"
    }]
  }, {
    path: "/uz/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___uz"
  }, {
    path: "/uz/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___uz"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___uz"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___uz"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___uz"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___uz"
    }]
  }, {
    path: "/uz/blog",
    component: _545b2785,
    name: "blog___uz"
  }, {
    path: "/uz/campaign",
    component: _18f690f0,
    name: "campaign___uz",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___uz"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___uz"
    }]
  }, {
    path: "/uz/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___uz"
  }, {
    path: "/uz/more-info",
    component: _39acbd0e,
    name: "more-info___uz",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___uz"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___uz"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___uz"
    }]
  }, {
    path: "/uz/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___uz"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___uz"
    }]
  }, {
    path: "/uz/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___uz"
  }, {
    path: "/uz/profile",
    component: _0c00593c,
    name: "profile___uz",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___uz"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___uz"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___uz"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___uz"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___uz"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___uz"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___uz"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___uz"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___uz"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___uz"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___uz"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___uz"
    }]
  }, {
    path: "/uz/referral",
    component: _00cbdc80,
    name: "referral___uz"
  }, {
    path: "/uz/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___uz"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___uz"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___uz"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___uz"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___uz"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___uz"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___uz"
    }]
  }, {
    path: "/vi/about-us",
    component: _9b5ef47e,
    name: "about-us___vi",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___vi"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___vi"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___vi"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___vi"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___vi"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___vi"
    }]
  }, {
    path: "/vi/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___vi"
  }, {
    path: "/vi/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___vi"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___vi"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___vi"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___vi"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___vi"
    }]
  }, {
    path: "/vi/blog",
    component: _545b2785,
    name: "blog___vi"
  }, {
    path: "/vi/campaign",
    component: _18f690f0,
    name: "campaign___vi",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___vi"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___vi"
    }]
  }, {
    path: "/vi/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___vi"
  }, {
    path: "/vi/more-info",
    component: _39acbd0e,
    name: "more-info___vi",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___vi"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___vi"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___vi"
    }]
  }, {
    path: "/vi/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___vi"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___vi"
    }]
  }, {
    path: "/vi/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___vi"
  }, {
    path: "/vi/profile",
    component: _0c00593c,
    name: "profile___vi",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___vi"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___vi"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___vi"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___vi"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___vi"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___vi"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___vi"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___vi"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___vi"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___vi"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___vi"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___vi"
    }]
  }, {
    path: "/vi/referral",
    component: _00cbdc80,
    name: "referral___vi"
  }, {
    path: "/vi/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___vi"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___vi"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___vi"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___vi"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___vi"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___vi"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___vi"
    }]
  }, {
    path: "/vls-BE/about-us",
    component: _9b5ef47e,
    name: "about-us___vls-BE",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___vls-BE"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___vls-BE"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___vls-BE"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___vls-BE"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___vls-BE"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___vls-BE"
    }]
  }, {
    path: "/vls-BE/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___vls-BE"
  }, {
    path: "/vls-BE/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___vls-BE"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___vls-BE"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___vls-BE"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___vls-BE"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___vls-BE"
    }]
  }, {
    path: "/vls-BE/blog",
    component: _545b2785,
    name: "blog___vls-BE"
  }, {
    path: "/vls-BE/campaign",
    component: _18f690f0,
    name: "campaign___vls-BE",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___vls-BE"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___vls-BE"
    }]
  }, {
    path: "/vls-BE/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___vls-BE"
  }, {
    path: "/vls-BE/more-info",
    component: _39acbd0e,
    name: "more-info___vls-BE",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___vls-BE"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___vls-BE"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___vls-BE"
    }]
  }, {
    path: "/vls-BE/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___vls-BE"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___vls-BE"
    }]
  }, {
    path: "/vls-BE/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___vls-BE"
  }, {
    path: "/vls-BE/profile",
    component: _0c00593c,
    name: "profile___vls-BE",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___vls-BE"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___vls-BE"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___vls-BE"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___vls-BE"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___vls-BE"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___vls-BE"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___vls-BE"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___vls-BE"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___vls-BE"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___vls-BE"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___vls-BE"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___vls-BE"
    }]
  }, {
    path: "/vls-BE/referral",
    component: _00cbdc80,
    name: "referral___vls-BE"
  }, {
    path: "/vls-BE/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___vls-BE"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___vls-BE"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___vls-BE"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___vls-BE"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___vls-BE"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___vls-BE"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___vls-BE"
    }]
  }, {
    path: "/zh-CN/about-us",
    component: _9b5ef47e,
    name: "about-us___zh-CN",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___zh-CN"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___zh-CN"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___zh-CN"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___zh-CN"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___zh-CN"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___zh-CN"
    }]
  }, {
    path: "/zh-CN/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___zh-CN"
  }, {
    path: "/zh-CN/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___zh-CN"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___zh-CN"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___zh-CN"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___zh-CN"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___zh-CN"
    }]
  }, {
    path: "/zh-CN/blog",
    component: _545b2785,
    name: "blog___zh-CN"
  }, {
    path: "/zh-CN/campaign",
    component: _18f690f0,
    name: "campaign___zh-CN",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___zh-CN"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___zh-CN"
    }]
  }, {
    path: "/zh-CN/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___zh-CN"
  }, {
    path: "/zh-CN/more-info",
    component: _39acbd0e,
    name: "more-info___zh-CN",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___zh-CN"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___zh-CN"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___zh-CN"
    }]
  }, {
    path: "/zh-CN/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___zh-CN"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___zh-CN"
    }]
  }, {
    path: "/zh-CN/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___zh-CN"
  }, {
    path: "/zh-CN/profile",
    component: _0c00593c,
    name: "profile___zh-CN",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___zh-CN"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___zh-CN"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___zh-CN"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___zh-CN"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___zh-CN"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___zh-CN"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___zh-CN"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___zh-CN"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___zh-CN"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___zh-CN"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___zh-CN"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___zh-CN"
    }]
  }, {
    path: "/zh-CN/referral",
    component: _00cbdc80,
    name: "referral___zh-CN"
  }, {
    path: "/zh-CN/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___zh-CN"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___zh-CN"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___zh-CN"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___zh-CN"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___zh-CN"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___zh-CN"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___zh-CN"
    }]
  }, {
    path: "/zh-TW/about-us",
    component: _9b5ef47e,
    name: "about-us___zh-TW",
    children: [{
      path: "about-airalo",
      component: _1d29a61b,
      name: "about-us-index-about-airalo___zh-TW"
    }, {
      path: "media-center",
      component: _733fad1f,
      name: "about-us-index-media-center___zh-TW"
    }, {
      path: "newsroom",
      component: _778037ef,
      name: "about-us-index-newsroom___zh-TW"
    }, {
      path: "press-releases",
      component: _b1086a52,
      name: "about-us-index-press-releases___zh-TW"
    }, {
      path: "newsroom/search",
      component: _24459f5b,
      name: "about-us-index-newsroom-search___zh-TW"
    }, {
      path: "press-releases/:slug",
      component: _042bee5c,
      name: "about-us-index-press-releases-slug___zh-TW"
    }]
  }, {
    path: "/zh-TW/airalo-careers",
    component: _0d231be5,
    name: "airalo-careers___zh-TW"
  }, {
    path: "/zh-TW/auth",
    component: _df14f3ea,
    children: [{
      path: "",
      component: _fd2bcf64,
      name: "auth-index___zh-TW"
    }, {
      path: "callback",
      component: _d6dec088,
      name: "auth-index-callback___zh-TW"
    }, {
      path: "google",
      component: _60e818a0,
      name: "auth-index-google___zh-TW"
    }, {
      path: "login",
      component: _1a3b6228,
      name: "auth-index-login___zh-TW"
    }, {
      path: "signup",
      component: _68f73bcf,
      name: "auth-index-signup___zh-TW"
    }]
  }, {
    path: "/zh-TW/blog",
    component: _545b2785,
    name: "blog___zh-TW"
  }, {
    path: "/zh-TW/campaign",
    component: _18f690f0,
    name: "campaign___zh-TW",
    children: [{
      path: "referral-terms-and-conditions",
      component: _71c42845,
      name: "campaign-referral-terms-and-conditions___zh-TW"
    }, {
      path: ":slug",
      component: _4ca6fc6e,
      name: "campaign-slug___zh-TW"
    }]
  }, {
    path: "/zh-TW/esim-qr-code",
    component: _4d343a7e,
    name: "esim-qr-code___zh-TW"
  }, {
    path: "/zh-TW/more-info",
    component: _39acbd0e,
    name: "more-info___zh-TW",
    children: [{
      path: "contact",
      component: _d0168fe6,
      name: "more-info-index-contact___zh-TW"
    }, {
      path: "privacy-policy",
      component: _60919460,
      name: "more-info-index-privacy-policy___zh-TW"
    }, {
      path: "terms-conditions",
      component: _e01c1072,
      name: "more-info-index-terms-conditions___zh-TW"
    }]
  }, {
    path: "/zh-TW/my-esims",
    component: _2a62cb63,
    children: [{
      path: "",
      component: _72b3fba6,
      name: "my-esims-index___zh-TW"
    }, {
      path: "archived",
      component: _ac3e1284,
      name: "my-esims-index-archived___zh-TW"
    }]
  }, {
    path: "/zh-TW/partner-with-us",
    component: _332c17d3,
    name: "partner-with-us___zh-TW"
  }, {
    path: "/zh-TW/profile",
    component: _0c00593c,
    name: "profile___zh-TW",
    children: [{
      path: "airmoney",
      component: _22d2509c,
      name: "profile-index-airmoney___zh-TW"
    }, {
      path: "change-email",
      component: _86ec1f3c,
      name: "profile-index-change-email___zh-TW"
    }, {
      path: "change-password",
      component: _b1b71e76,
      name: "profile-index-change-password___zh-TW"
    }, {
      path: "credit-cards",
      component: _6c95d9b5,
      name: "profile-index-credit-cards___zh-TW"
    }, {
      path: "orders",
      component: _6fba946a,
      name: "profile-index-orders___zh-TW"
    }, {
      path: "organizations",
      component: _3ca1578d,
      name: "profile-index-organizations___zh-TW"
    }, {
      path: "referral",
      component: _3d6af1c3,
      name: "profile-index-referral___zh-TW"
    }, {
      path: "settings",
      component: _3164c2c9,
      name: "profile-index-settings___zh-TW"
    }, {
      path: "identity-authentication/default",
      component: _3a2735c6,
      name: "profile-index-identity-authentication-default___zh-TW"
    }, {
      path: "identity-authentication/onetime",
      component: _43cf1690,
      name: "profile-index-identity-authentication-onetime___zh-TW"
    }, {
      path: "settings/delete-account-success",
      component: _e886184c,
      name: "profile-index-settings-delete-account-success___zh-TW"
    }, {
      path: "orders/:id",
      component: _5fc5f336,
      name: "profile-index-orders-id___zh-TW"
    }]
  }, {
    path: "/zh-TW/referral",
    component: _00cbdc80,
    name: "referral___zh-TW"
  }, {
    path: "/zh-TW/verify",
    component: _129c24c8,
    children: [{
      path: "",
      component: _3a3661c2,
      name: "verify-index___zh-TW"
    }, {
      path: "activation",
      component: _546c44dc,
      name: "verify-index-activation___zh-TW"
    }, {
      path: "change-email",
      component: _17937505,
      name: "verify-index-change-email___zh-TW"
    }, {
      path: "reset",
      component: _a8ff02c2,
      name: "verify-index-reset___zh-TW"
    }, {
      path: "respass-1",
      component: _eacaed36,
      name: "verify-index-respass-1___zh-TW"
    }, {
      path: "respass-2",
      component: _1f7f3c26,
      name: "verify-index-respass-2___zh-TW"
    }, {
      path: "respass-success",
      component: _1993f812,
      name: "verify-index-respass-success___zh-TW"
    }]
  }, {
    path: "/ar/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ar"
  }, {
    path: "/ar/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ar"
  }, {
    path: "/ar/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ar"
  }, {
    path: "/ar/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ar"
  }, {
    path: "/ar/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ar"
  }, {
    path: "/ar/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ar"
  }, {
    path: "/ar/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ar"
  }, {
    path: "/ar/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ar"
  }, {
    path: "/ar/order/fail",
    component: _656aaf9f,
    name: "order-fail___ar"
  }, {
    path: "/ar/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ar"
  }, {
    path: "/ar/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ar"
  }, {
    path: "/ar/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ar"
  }, {
    path: "/ar/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ar"
  }, {
    path: "/ar/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ar"
  }, {
    path: "/ar/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ar"
  }, {
    path: "/az/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___az"
  }, {
    path: "/az/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___az"
  }, {
    path: "/az/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___az"
  }, {
    path: "/az/about-us/impact",
    component: _23563732,
    name: "about-us-impact___az"
  }, {
    path: "/az/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___az"
  }, {
    path: "/az/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___az"
  }, {
    path: "/az/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___az"
  }, {
    path: "/az/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___az"
  }, {
    path: "/az/order/fail",
    component: _656aaf9f,
    name: "order-fail___az"
  }, {
    path: "/az/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___az"
  }, {
    path: "/az/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___az"
  }, {
    path: "/az/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___az"
  }, {
    path: "/az/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___az"
  }, {
    path: "/az/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___az"
  }, {
    path: "/az/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___az"
  }, {
    path: "/bg/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___bg"
  }, {
    path: "/bg/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___bg"
  }, {
    path: "/bg/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___bg"
  }, {
    path: "/bg/about-us/impact",
    component: _23563732,
    name: "about-us-impact___bg"
  }, {
    path: "/bg/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___bg"
  }, {
    path: "/bg/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___bg"
  }, {
    path: "/bg/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___bg"
  }, {
    path: "/bg/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___bg"
  }, {
    path: "/bg/order/fail",
    component: _656aaf9f,
    name: "order-fail___bg"
  }, {
    path: "/bg/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___bg"
  }, {
    path: "/bg/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___bg"
  }, {
    path: "/bg/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___bg"
  }, {
    path: "/bg/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___bg"
  }, {
    path: "/bg/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___bg"
  }, {
    path: "/bg/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___bg"
  }, {
    path: "/bs/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___bs"
  }, {
    path: "/bs/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___bs"
  }, {
    path: "/bs/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___bs"
  }, {
    path: "/bs/about-us/impact",
    component: _23563732,
    name: "about-us-impact___bs"
  }, {
    path: "/bs/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___bs"
  }, {
    path: "/bs/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___bs"
  }, {
    path: "/bs/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___bs"
  }, {
    path: "/bs/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___bs"
  }, {
    path: "/bs/order/fail",
    component: _656aaf9f,
    name: "order-fail___bs"
  }, {
    path: "/bs/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___bs"
  }, {
    path: "/bs/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___bs"
  }, {
    path: "/bs/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___bs"
  }, {
    path: "/bs/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___bs"
  }, {
    path: "/bs/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___bs"
  }, {
    path: "/bs/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___bs"
  }, {
    path: "/cs/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___cs"
  }, {
    path: "/cs/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___cs"
  }, {
    path: "/cs/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___cs"
  }, {
    path: "/cs/about-us/impact",
    component: _23563732,
    name: "about-us-impact___cs"
  }, {
    path: "/cs/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___cs"
  }, {
    path: "/cs/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___cs"
  }, {
    path: "/cs/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___cs"
  }, {
    path: "/cs/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___cs"
  }, {
    path: "/cs/order/fail",
    component: _656aaf9f,
    name: "order-fail___cs"
  }, {
    path: "/cs/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___cs"
  }, {
    path: "/cs/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___cs"
  }, {
    path: "/cs/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___cs"
  }, {
    path: "/cs/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___cs"
  }, {
    path: "/cs/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___cs"
  }, {
    path: "/cs/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___cs"
  }, {
    path: "/da/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___da"
  }, {
    path: "/da/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___da"
  }, {
    path: "/da/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___da"
  }, {
    path: "/da/about-us/impact",
    component: _23563732,
    name: "about-us-impact___da"
  }, {
    path: "/da/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___da"
  }, {
    path: "/da/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___da"
  }, {
    path: "/da/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___da"
  }, {
    path: "/da/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___da"
  }, {
    path: "/da/order/fail",
    component: _656aaf9f,
    name: "order-fail___da"
  }, {
    path: "/da/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___da"
  }, {
    path: "/da/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___da"
  }, {
    path: "/da/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___da"
  }, {
    path: "/da/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___da"
  }, {
    path: "/da/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___da"
  }, {
    path: "/da/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___da"
  }, {
    path: "/de/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___de"
  }, {
    path: "/de/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___de"
  }, {
    path: "/de/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___de"
  }, {
    path: "/de/about-us/impact",
    component: _23563732,
    name: "about-us-impact___de"
  }, {
    path: "/de/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___de"
  }, {
    path: "/de/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___de"
  }, {
    path: "/de/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___de"
  }, {
    path: "/de/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___de"
  }, {
    path: "/de/order/fail",
    component: _656aaf9f,
    name: "order-fail___de"
  }, {
    path: "/de/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___de"
  }, {
    path: "/de/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___de"
  }, {
    path: "/de/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___de"
  }, {
    path: "/de/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___de"
  }, {
    path: "/de/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___de"
  }, {
    path: "/de/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___de"
  }, {
    path: "/el/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___el"
  }, {
    path: "/el/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___el"
  }, {
    path: "/el/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___el"
  }, {
    path: "/el/about-us/impact",
    component: _23563732,
    name: "about-us-impact___el"
  }, {
    path: "/el/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___el"
  }, {
    path: "/el/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___el"
  }, {
    path: "/el/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___el"
  }, {
    path: "/el/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___el"
  }, {
    path: "/el/order/fail",
    component: _656aaf9f,
    name: "order-fail___el"
  }, {
    path: "/el/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___el"
  }, {
    path: "/el/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___el"
  }, {
    path: "/el/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___el"
  }, {
    path: "/el/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___el"
  }, {
    path: "/el/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___el"
  }, {
    path: "/el/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___el"
  }, {
    path: "/es-419/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___es-419"
  }, {
    path: "/es-419/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___es-419"
  }, {
    path: "/es-419/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___es-419"
  }, {
    path: "/es-419/about-us/impact",
    component: _23563732,
    name: "about-us-impact___es-419"
  }, {
    path: "/es-419/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___es-419"
  }, {
    path: "/es-419/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___es-419"
  }, {
    path: "/es-419/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___es-419"
  }, {
    path: "/es-419/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___es-419"
  }, {
    path: "/es-419/order/fail",
    component: _656aaf9f,
    name: "order-fail___es-419"
  }, {
    path: "/es-419/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___es-419"
  }, {
    path: "/es-419/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___es-419"
  }, {
    path: "/es-419/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___es-419"
  }, {
    path: "/es-419/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___es-419"
  }, {
    path: "/es-419/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___es-419"
  }, {
    path: "/es-419/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___es-419"
  }, {
    path: "/es-ES/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___es-ES"
  }, {
    path: "/es-ES/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___es-ES"
  }, {
    path: "/es-ES/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___es-ES"
  }, {
    path: "/es-ES/about-us/impact",
    component: _23563732,
    name: "about-us-impact___es-ES"
  }, {
    path: "/es-ES/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___es-ES"
  }, {
    path: "/es-ES/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___es-ES"
  }, {
    path: "/es-ES/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___es-ES"
  }, {
    path: "/es-ES/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___es-ES"
  }, {
    path: "/es-ES/order/fail",
    component: _656aaf9f,
    name: "order-fail___es-ES"
  }, {
    path: "/es-ES/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___es-ES"
  }, {
    path: "/es-ES/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___es-ES"
  }, {
    path: "/es-ES/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___es-ES"
  }, {
    path: "/es-ES/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___es-ES"
  }, {
    path: "/es-ES/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___es-ES"
  }, {
    path: "/es-ES/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___es-ES"
  }, {
    path: "/et/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___et"
  }, {
    path: "/et/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___et"
  }, {
    path: "/et/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___et"
  }, {
    path: "/et/about-us/impact",
    component: _23563732,
    name: "about-us-impact___et"
  }, {
    path: "/et/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___et"
  }, {
    path: "/et/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___et"
  }, {
    path: "/et/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___et"
  }, {
    path: "/et/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___et"
  }, {
    path: "/et/order/fail",
    component: _656aaf9f,
    name: "order-fail___et"
  }, {
    path: "/et/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___et"
  }, {
    path: "/et/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___et"
  }, {
    path: "/et/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___et"
  }, {
    path: "/et/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___et"
  }, {
    path: "/et/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___et"
  }, {
    path: "/et/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___et"
  }, {
    path: "/fa/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___fa"
  }, {
    path: "/fa/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___fa"
  }, {
    path: "/fa/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___fa"
  }, {
    path: "/fa/about-us/impact",
    component: _23563732,
    name: "about-us-impact___fa"
  }, {
    path: "/fa/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___fa"
  }, {
    path: "/fa/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___fa"
  }, {
    path: "/fa/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___fa"
  }, {
    path: "/fa/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___fa"
  }, {
    path: "/fa/order/fail",
    component: _656aaf9f,
    name: "order-fail___fa"
  }, {
    path: "/fa/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___fa"
  }, {
    path: "/fa/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___fa"
  }, {
    path: "/fa/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___fa"
  }, {
    path: "/fa/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___fa"
  }, {
    path: "/fa/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___fa"
  }, {
    path: "/fa/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___fa"
  }, {
    path: "/fi/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___fi"
  }, {
    path: "/fi/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___fi"
  }, {
    path: "/fi/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___fi"
  }, {
    path: "/fi/about-us/impact",
    component: _23563732,
    name: "about-us-impact___fi"
  }, {
    path: "/fi/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___fi"
  }, {
    path: "/fi/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___fi"
  }, {
    path: "/fi/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___fi"
  }, {
    path: "/fi/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___fi"
  }, {
    path: "/fi/order/fail",
    component: _656aaf9f,
    name: "order-fail___fi"
  }, {
    path: "/fi/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___fi"
  }, {
    path: "/fi/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___fi"
  }, {
    path: "/fi/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___fi"
  }, {
    path: "/fi/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___fi"
  }, {
    path: "/fi/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___fi"
  }, {
    path: "/fi/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___fi"
  }, {
    path: "/fil/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___fil"
  }, {
    path: "/fil/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___fil"
  }, {
    path: "/fil/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___fil"
  }, {
    path: "/fil/about-us/impact",
    component: _23563732,
    name: "about-us-impact___fil"
  }, {
    path: "/fil/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___fil"
  }, {
    path: "/fil/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___fil"
  }, {
    path: "/fil/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___fil"
  }, {
    path: "/fil/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___fil"
  }, {
    path: "/fil/order/fail",
    component: _656aaf9f,
    name: "order-fail___fil"
  }, {
    path: "/fil/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___fil"
  }, {
    path: "/fil/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___fil"
  }, {
    path: "/fil/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___fil"
  }, {
    path: "/fil/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___fil"
  }, {
    path: "/fil/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___fil"
  }, {
    path: "/fil/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___fil"
  }, {
    path: "/fr/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___fr"
  }, {
    path: "/fr/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___fr"
  }, {
    path: "/fr/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___fr"
  }, {
    path: "/fr/about-us/impact",
    component: _23563732,
    name: "about-us-impact___fr"
  }, {
    path: "/fr/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___fr"
  }, {
    path: "/fr/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___fr"
  }, {
    path: "/fr/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___fr"
  }, {
    path: "/fr/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___fr"
  }, {
    path: "/fr/order/fail",
    component: _656aaf9f,
    name: "order-fail___fr"
  }, {
    path: "/fr/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___fr"
  }, {
    path: "/fr/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___fr"
  }, {
    path: "/fr/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___fr"
  }, {
    path: "/fr/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___fr"
  }, {
    path: "/fr/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___fr"
  }, {
    path: "/fr/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___fr"
  }, {
    path: "/he/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___he"
  }, {
    path: "/he/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___he"
  }, {
    path: "/he/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___he"
  }, {
    path: "/he/about-us/impact",
    component: _23563732,
    name: "about-us-impact___he"
  }, {
    path: "/he/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___he"
  }, {
    path: "/he/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___he"
  }, {
    path: "/he/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___he"
  }, {
    path: "/he/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___he"
  }, {
    path: "/he/order/fail",
    component: _656aaf9f,
    name: "order-fail___he"
  }, {
    path: "/he/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___he"
  }, {
    path: "/he/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___he"
  }, {
    path: "/he/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___he"
  }, {
    path: "/he/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___he"
  }, {
    path: "/he/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___he"
  }, {
    path: "/he/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___he"
  }, {
    path: "/hi/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___hi"
  }, {
    path: "/hi/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___hi"
  }, {
    path: "/hi/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___hi"
  }, {
    path: "/hi/about-us/impact",
    component: _23563732,
    name: "about-us-impact___hi"
  }, {
    path: "/hi/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___hi"
  }, {
    path: "/hi/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___hi"
  }, {
    path: "/hi/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___hi"
  }, {
    path: "/hi/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___hi"
  }, {
    path: "/hi/order/fail",
    component: _656aaf9f,
    name: "order-fail___hi"
  }, {
    path: "/hi/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___hi"
  }, {
    path: "/hi/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___hi"
  }, {
    path: "/hi/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___hi"
  }, {
    path: "/hi/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___hi"
  }, {
    path: "/hi/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___hi"
  }, {
    path: "/hi/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___hi"
  }, {
    path: "/hr/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___hr"
  }, {
    path: "/hr/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___hr"
  }, {
    path: "/hr/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___hr"
  }, {
    path: "/hr/about-us/impact",
    component: _23563732,
    name: "about-us-impact___hr"
  }, {
    path: "/hr/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___hr"
  }, {
    path: "/hr/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___hr"
  }, {
    path: "/hr/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___hr"
  }, {
    path: "/hr/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___hr"
  }, {
    path: "/hr/order/fail",
    component: _656aaf9f,
    name: "order-fail___hr"
  }, {
    path: "/hr/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___hr"
  }, {
    path: "/hr/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___hr"
  }, {
    path: "/hr/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___hr"
  }, {
    path: "/hr/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___hr"
  }, {
    path: "/hr/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___hr"
  }, {
    path: "/hr/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___hr"
  }, {
    path: "/hu/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___hu"
  }, {
    path: "/hu/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___hu"
  }, {
    path: "/hu/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___hu"
  }, {
    path: "/hu/about-us/impact",
    component: _23563732,
    name: "about-us-impact___hu"
  }, {
    path: "/hu/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___hu"
  }, {
    path: "/hu/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___hu"
  }, {
    path: "/hu/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___hu"
  }, {
    path: "/hu/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___hu"
  }, {
    path: "/hu/order/fail",
    component: _656aaf9f,
    name: "order-fail___hu"
  }, {
    path: "/hu/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___hu"
  }, {
    path: "/hu/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___hu"
  }, {
    path: "/hu/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___hu"
  }, {
    path: "/hu/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___hu"
  }, {
    path: "/hu/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___hu"
  }, {
    path: "/hu/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___hu"
  }, {
    path: "/hy-AM/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___hy-AM"
  }, {
    path: "/hy-AM/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___hy-AM"
  }, {
    path: "/hy-AM/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___hy-AM"
  }, {
    path: "/hy-AM/about-us/impact",
    component: _23563732,
    name: "about-us-impact___hy-AM"
  }, {
    path: "/hy-AM/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___hy-AM"
  }, {
    path: "/hy-AM/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___hy-AM"
  }, {
    path: "/hy-AM/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___hy-AM"
  }, {
    path: "/hy-AM/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___hy-AM"
  }, {
    path: "/hy-AM/order/fail",
    component: _656aaf9f,
    name: "order-fail___hy-AM"
  }, {
    path: "/hy-AM/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___hy-AM"
  }, {
    path: "/hy-AM/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___hy-AM"
  }, {
    path: "/hy-AM/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___hy-AM"
  }, {
    path: "/hy-AM/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___hy-AM"
  }, {
    path: "/hy-AM/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___hy-AM"
  }, {
    path: "/hy-AM/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___hy-AM"
  }, {
    path: "/id/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___id"
  }, {
    path: "/id/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___id"
  }, {
    path: "/id/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___id"
  }, {
    path: "/id/about-us/impact",
    component: _23563732,
    name: "about-us-impact___id"
  }, {
    path: "/id/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___id"
  }, {
    path: "/id/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___id"
  }, {
    path: "/id/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___id"
  }, {
    path: "/id/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___id"
  }, {
    path: "/id/order/fail",
    component: _656aaf9f,
    name: "order-fail___id"
  }, {
    path: "/id/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___id"
  }, {
    path: "/id/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___id"
  }, {
    path: "/id/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___id"
  }, {
    path: "/id/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___id"
  }, {
    path: "/id/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___id"
  }, {
    path: "/id/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___id"
  }, {
    path: "/it/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___it"
  }, {
    path: "/it/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___it"
  }, {
    path: "/it/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___it"
  }, {
    path: "/it/about-us/impact",
    component: _23563732,
    name: "about-us-impact___it"
  }, {
    path: "/it/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___it"
  }, {
    path: "/it/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___it"
  }, {
    path: "/it/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___it"
  }, {
    path: "/it/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___it"
  }, {
    path: "/it/order/fail",
    component: _656aaf9f,
    name: "order-fail___it"
  }, {
    path: "/it/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___it"
  }, {
    path: "/it/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___it"
  }, {
    path: "/it/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___it"
  }, {
    path: "/it/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___it"
  }, {
    path: "/it/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___it"
  }, {
    path: "/it/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___it"
  }, {
    path: "/ja/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ja"
  }, {
    path: "/ja/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ja"
  }, {
    path: "/ja/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ja"
  }, {
    path: "/ja/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ja"
  }, {
    path: "/ja/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ja"
  }, {
    path: "/ja/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ja"
  }, {
    path: "/ja/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ja"
  }, {
    path: "/ja/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ja"
  }, {
    path: "/ja/order/fail",
    component: _656aaf9f,
    name: "order-fail___ja"
  }, {
    path: "/ja/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ja"
  }, {
    path: "/ja/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ja"
  }, {
    path: "/ja/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ja"
  }, {
    path: "/ja/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ja"
  }, {
    path: "/ja/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ja"
  }, {
    path: "/ja/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ja"
  }, {
    path: "/ka/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ka"
  }, {
    path: "/ka/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ka"
  }, {
    path: "/ka/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ka"
  }, {
    path: "/ka/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ka"
  }, {
    path: "/ka/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ka"
  }, {
    path: "/ka/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ka"
  }, {
    path: "/ka/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ka"
  }, {
    path: "/ka/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ka"
  }, {
    path: "/ka/order/fail",
    component: _656aaf9f,
    name: "order-fail___ka"
  }, {
    path: "/ka/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ka"
  }, {
    path: "/ka/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ka"
  }, {
    path: "/ka/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ka"
  }, {
    path: "/ka/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ka"
  }, {
    path: "/ka/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ka"
  }, {
    path: "/ka/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ka"
  }, {
    path: "/kk/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___kk"
  }, {
    path: "/kk/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___kk"
  }, {
    path: "/kk/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___kk"
  }, {
    path: "/kk/about-us/impact",
    component: _23563732,
    name: "about-us-impact___kk"
  }, {
    path: "/kk/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___kk"
  }, {
    path: "/kk/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___kk"
  }, {
    path: "/kk/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___kk"
  }, {
    path: "/kk/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___kk"
  }, {
    path: "/kk/order/fail",
    component: _656aaf9f,
    name: "order-fail___kk"
  }, {
    path: "/kk/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___kk"
  }, {
    path: "/kk/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___kk"
  }, {
    path: "/kk/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___kk"
  }, {
    path: "/kk/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___kk"
  }, {
    path: "/kk/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___kk"
  }, {
    path: "/kk/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___kk"
  }, {
    path: "/kmr/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___kmr"
  }, {
    path: "/kmr/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___kmr"
  }, {
    path: "/kmr/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___kmr"
  }, {
    path: "/kmr/about-us/impact",
    component: _23563732,
    name: "about-us-impact___kmr"
  }, {
    path: "/kmr/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___kmr"
  }, {
    path: "/kmr/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___kmr"
  }, {
    path: "/kmr/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___kmr"
  }, {
    path: "/kmr/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___kmr"
  }, {
    path: "/kmr/order/fail",
    component: _656aaf9f,
    name: "order-fail___kmr"
  }, {
    path: "/kmr/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___kmr"
  }, {
    path: "/kmr/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___kmr"
  }, {
    path: "/kmr/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___kmr"
  }, {
    path: "/kmr/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___kmr"
  }, {
    path: "/kmr/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___kmr"
  }, {
    path: "/kmr/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___kmr"
  }, {
    path: "/ko/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ko"
  }, {
    path: "/ko/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ko"
  }, {
    path: "/ko/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ko"
  }, {
    path: "/ko/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ko"
  }, {
    path: "/ko/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ko"
  }, {
    path: "/ko/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ko"
  }, {
    path: "/ko/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ko"
  }, {
    path: "/ko/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ko"
  }, {
    path: "/ko/order/fail",
    component: _656aaf9f,
    name: "order-fail___ko"
  }, {
    path: "/ko/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ko"
  }, {
    path: "/ko/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ko"
  }, {
    path: "/ko/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ko"
  }, {
    path: "/ko/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ko"
  }, {
    path: "/ko/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ko"
  }, {
    path: "/ko/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ko"
  }, {
    path: "/ky/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ky"
  }, {
    path: "/ky/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ky"
  }, {
    path: "/ky/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ky"
  }, {
    path: "/ky/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ky"
  }, {
    path: "/ky/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ky"
  }, {
    path: "/ky/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ky"
  }, {
    path: "/ky/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ky"
  }, {
    path: "/ky/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ky"
  }, {
    path: "/ky/order/fail",
    component: _656aaf9f,
    name: "order-fail___ky"
  }, {
    path: "/ky/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ky"
  }, {
    path: "/ky/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ky"
  }, {
    path: "/ky/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ky"
  }, {
    path: "/ky/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ky"
  }, {
    path: "/ky/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ky"
  }, {
    path: "/ky/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ky"
  }, {
    path: "/lt/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___lt"
  }, {
    path: "/lt/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___lt"
  }, {
    path: "/lt/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___lt"
  }, {
    path: "/lt/about-us/impact",
    component: _23563732,
    name: "about-us-impact___lt"
  }, {
    path: "/lt/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___lt"
  }, {
    path: "/lt/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___lt"
  }, {
    path: "/lt/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___lt"
  }, {
    path: "/lt/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___lt"
  }, {
    path: "/lt/order/fail",
    component: _656aaf9f,
    name: "order-fail___lt"
  }, {
    path: "/lt/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___lt"
  }, {
    path: "/lt/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___lt"
  }, {
    path: "/lt/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___lt"
  }, {
    path: "/lt/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___lt"
  }, {
    path: "/lt/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___lt"
  }, {
    path: "/lt/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___lt"
  }, {
    path: "/lv/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___lv"
  }, {
    path: "/lv/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___lv"
  }, {
    path: "/lv/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___lv"
  }, {
    path: "/lv/about-us/impact",
    component: _23563732,
    name: "about-us-impact___lv"
  }, {
    path: "/lv/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___lv"
  }, {
    path: "/lv/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___lv"
  }, {
    path: "/lv/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___lv"
  }, {
    path: "/lv/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___lv"
  }, {
    path: "/lv/order/fail",
    component: _656aaf9f,
    name: "order-fail___lv"
  }, {
    path: "/lv/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___lv"
  }, {
    path: "/lv/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___lv"
  }, {
    path: "/lv/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___lv"
  }, {
    path: "/lv/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___lv"
  }, {
    path: "/lv/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___lv"
  }, {
    path: "/lv/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___lv"
  }, {
    path: "/mk/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___mk"
  }, {
    path: "/mk/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___mk"
  }, {
    path: "/mk/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___mk"
  }, {
    path: "/mk/about-us/impact",
    component: _23563732,
    name: "about-us-impact___mk"
  }, {
    path: "/mk/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___mk"
  }, {
    path: "/mk/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___mk"
  }, {
    path: "/mk/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___mk"
  }, {
    path: "/mk/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___mk"
  }, {
    path: "/mk/order/fail",
    component: _656aaf9f,
    name: "order-fail___mk"
  }, {
    path: "/mk/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___mk"
  }, {
    path: "/mk/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___mk"
  }, {
    path: "/mk/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___mk"
  }, {
    path: "/mk/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___mk"
  }, {
    path: "/mk/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___mk"
  }, {
    path: "/mk/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___mk"
  }, {
    path: "/ms/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ms"
  }, {
    path: "/ms/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ms"
  }, {
    path: "/ms/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ms"
  }, {
    path: "/ms/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ms"
  }, {
    path: "/ms/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ms"
  }, {
    path: "/ms/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ms"
  }, {
    path: "/ms/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ms"
  }, {
    path: "/ms/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ms"
  }, {
    path: "/ms/order/fail",
    component: _656aaf9f,
    name: "order-fail___ms"
  }, {
    path: "/ms/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ms"
  }, {
    path: "/ms/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ms"
  }, {
    path: "/ms/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ms"
  }, {
    path: "/ms/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ms"
  }, {
    path: "/ms/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ms"
  }, {
    path: "/ms/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ms"
  }, {
    path: "/nb/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___nb"
  }, {
    path: "/nb/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___nb"
  }, {
    path: "/nb/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___nb"
  }, {
    path: "/nb/about-us/impact",
    component: _23563732,
    name: "about-us-impact___nb"
  }, {
    path: "/nb/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___nb"
  }, {
    path: "/nb/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___nb"
  }, {
    path: "/nb/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___nb"
  }, {
    path: "/nb/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___nb"
  }, {
    path: "/nb/order/fail",
    component: _656aaf9f,
    name: "order-fail___nb"
  }, {
    path: "/nb/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___nb"
  }, {
    path: "/nb/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___nb"
  }, {
    path: "/nb/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___nb"
  }, {
    path: "/nb/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___nb"
  }, {
    path: "/nb/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___nb"
  }, {
    path: "/nb/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___nb"
  }, {
    path: "/nl/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___nl"
  }, {
    path: "/nl/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___nl"
  }, {
    path: "/nl/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___nl"
  }, {
    path: "/nl/about-us/impact",
    component: _23563732,
    name: "about-us-impact___nl"
  }, {
    path: "/nl/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___nl"
  }, {
    path: "/nl/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___nl"
  }, {
    path: "/nl/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___nl"
  }, {
    path: "/nl/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___nl"
  }, {
    path: "/nl/order/fail",
    component: _656aaf9f,
    name: "order-fail___nl"
  }, {
    path: "/nl/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___nl"
  }, {
    path: "/nl/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___nl"
  }, {
    path: "/nl/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___nl"
  }, {
    path: "/nl/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___nl"
  }, {
    path: "/nl/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___nl"
  }, {
    path: "/nl/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___nl"
  }, {
    path: "/pl/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___pl"
  }, {
    path: "/pl/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___pl"
  }, {
    path: "/pl/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___pl"
  }, {
    path: "/pl/about-us/impact",
    component: _23563732,
    name: "about-us-impact___pl"
  }, {
    path: "/pl/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___pl"
  }, {
    path: "/pl/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___pl"
  }, {
    path: "/pl/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___pl"
  }, {
    path: "/pl/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___pl"
  }, {
    path: "/pl/order/fail",
    component: _656aaf9f,
    name: "order-fail___pl"
  }, {
    path: "/pl/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___pl"
  }, {
    path: "/pl/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___pl"
  }, {
    path: "/pl/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___pl"
  }, {
    path: "/pl/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___pl"
  }, {
    path: "/pl/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___pl"
  }, {
    path: "/pl/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___pl"
  }, {
    path: "/pt-BR/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___pt-BR"
  }, {
    path: "/pt-BR/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___pt-BR"
  }, {
    path: "/pt-BR/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___pt-BR"
  }, {
    path: "/pt-BR/about-us/impact",
    component: _23563732,
    name: "about-us-impact___pt-BR"
  }, {
    path: "/pt-BR/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___pt-BR"
  }, {
    path: "/pt-BR/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___pt-BR"
  }, {
    path: "/pt-BR/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___pt-BR"
  }, {
    path: "/pt-BR/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___pt-BR"
  }, {
    path: "/pt-BR/order/fail",
    component: _656aaf9f,
    name: "order-fail___pt-BR"
  }, {
    path: "/pt-BR/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___pt-BR"
  }, {
    path: "/pt-BR/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___pt-BR"
  }, {
    path: "/pt-BR/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___pt-BR"
  }, {
    path: "/pt-BR/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___pt-BR"
  }, {
    path: "/pt-BR/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___pt-BR"
  }, {
    path: "/pt-BR/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___pt-BR"
  }, {
    path: "/pt-PT/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___pt-PT"
  }, {
    path: "/pt-PT/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___pt-PT"
  }, {
    path: "/pt-PT/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___pt-PT"
  }, {
    path: "/pt-PT/about-us/impact",
    component: _23563732,
    name: "about-us-impact___pt-PT"
  }, {
    path: "/pt-PT/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___pt-PT"
  }, {
    path: "/pt-PT/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___pt-PT"
  }, {
    path: "/pt-PT/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___pt-PT"
  }, {
    path: "/pt-PT/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___pt-PT"
  }, {
    path: "/pt-PT/order/fail",
    component: _656aaf9f,
    name: "order-fail___pt-PT"
  }, {
    path: "/pt-PT/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___pt-PT"
  }, {
    path: "/pt-PT/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___pt-PT"
  }, {
    path: "/pt-PT/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___pt-PT"
  }, {
    path: "/pt-PT/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___pt-PT"
  }, {
    path: "/pt-PT/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___pt-PT"
  }, {
    path: "/pt-PT/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___pt-PT"
  }, {
    path: "/ro/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ro"
  }, {
    path: "/ro/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ro"
  }, {
    path: "/ro/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ro"
  }, {
    path: "/ro/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ro"
  }, {
    path: "/ro/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ro"
  }, {
    path: "/ro/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ro"
  }, {
    path: "/ro/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ro"
  }, {
    path: "/ro/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ro"
  }, {
    path: "/ro/order/fail",
    component: _656aaf9f,
    name: "order-fail___ro"
  }, {
    path: "/ro/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ro"
  }, {
    path: "/ro/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ro"
  }, {
    path: "/ro/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ro"
  }, {
    path: "/ro/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ro"
  }, {
    path: "/ro/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ro"
  }, {
    path: "/ro/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ro"
  }, {
    path: "/ru/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ru"
  }, {
    path: "/ru/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ru"
  }, {
    path: "/ru/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ru"
  }, {
    path: "/ru/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ru"
  }, {
    path: "/ru/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ru"
  }, {
    path: "/ru/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ru"
  }, {
    path: "/ru/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ru"
  }, {
    path: "/ru/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ru"
  }, {
    path: "/ru/order/fail",
    component: _656aaf9f,
    name: "order-fail___ru"
  }, {
    path: "/ru/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ru"
  }, {
    path: "/ru/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ru"
  }, {
    path: "/ru/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ru"
  }, {
    path: "/ru/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ru"
  }, {
    path: "/ru/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ru"
  }, {
    path: "/ru/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ru"
  }, {
    path: "/sk/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___sk"
  }, {
    path: "/sk/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___sk"
  }, {
    path: "/sk/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___sk"
  }, {
    path: "/sk/about-us/impact",
    component: _23563732,
    name: "about-us-impact___sk"
  }, {
    path: "/sk/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___sk"
  }, {
    path: "/sk/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___sk"
  }, {
    path: "/sk/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___sk"
  }, {
    path: "/sk/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___sk"
  }, {
    path: "/sk/order/fail",
    component: _656aaf9f,
    name: "order-fail___sk"
  }, {
    path: "/sk/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___sk"
  }, {
    path: "/sk/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___sk"
  }, {
    path: "/sk/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___sk"
  }, {
    path: "/sk/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___sk"
  }, {
    path: "/sk/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___sk"
  }, {
    path: "/sk/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___sk"
  }, {
    path: "/sq/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___sq"
  }, {
    path: "/sq/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___sq"
  }, {
    path: "/sq/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___sq"
  }, {
    path: "/sq/about-us/impact",
    component: _23563732,
    name: "about-us-impact___sq"
  }, {
    path: "/sq/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___sq"
  }, {
    path: "/sq/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___sq"
  }, {
    path: "/sq/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___sq"
  }, {
    path: "/sq/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___sq"
  }, {
    path: "/sq/order/fail",
    component: _656aaf9f,
    name: "order-fail___sq"
  }, {
    path: "/sq/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___sq"
  }, {
    path: "/sq/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___sq"
  }, {
    path: "/sq/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___sq"
  }, {
    path: "/sq/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___sq"
  }, {
    path: "/sq/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___sq"
  }, {
    path: "/sq/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___sq"
  }, {
    path: "/sr-CS/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___sr-CS"
  }, {
    path: "/sr-CS/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___sr-CS"
  }, {
    path: "/sr-CS/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___sr-CS"
  }, {
    path: "/sr-CS/about-us/impact",
    component: _23563732,
    name: "about-us-impact___sr-CS"
  }, {
    path: "/sr-CS/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___sr-CS"
  }, {
    path: "/sr-CS/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___sr-CS"
  }, {
    path: "/sr-CS/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___sr-CS"
  }, {
    path: "/sr-CS/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___sr-CS"
  }, {
    path: "/sr-CS/order/fail",
    component: _656aaf9f,
    name: "order-fail___sr-CS"
  }, {
    path: "/sr-CS/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___sr-CS"
  }, {
    path: "/sr-CS/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___sr-CS"
  }, {
    path: "/sr-CS/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___sr-CS"
  }, {
    path: "/sr-CS/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___sr-CS"
  }, {
    path: "/sr-CS/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___sr-CS"
  }, {
    path: "/sr-CS/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___sr-CS"
  }, {
    path: "/sv-SE/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___sv-SE"
  }, {
    path: "/sv-SE/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___sv-SE"
  }, {
    path: "/sv-SE/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___sv-SE"
  }, {
    path: "/sv-SE/about-us/impact",
    component: _23563732,
    name: "about-us-impact___sv-SE"
  }, {
    path: "/sv-SE/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___sv-SE"
  }, {
    path: "/sv-SE/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___sv-SE"
  }, {
    path: "/sv-SE/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___sv-SE"
  }, {
    path: "/sv-SE/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___sv-SE"
  }, {
    path: "/sv-SE/order/fail",
    component: _656aaf9f,
    name: "order-fail___sv-SE"
  }, {
    path: "/sv-SE/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___sv-SE"
  }, {
    path: "/sv-SE/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___sv-SE"
  }, {
    path: "/sv-SE/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___sv-SE"
  }, {
    path: "/sv-SE/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___sv-SE"
  }, {
    path: "/sv-SE/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___sv-SE"
  }, {
    path: "/sv-SE/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___sv-SE"
  }, {
    path: "/th/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___th"
  }, {
    path: "/th/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___th"
  }, {
    path: "/th/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___th"
  }, {
    path: "/th/about-us/impact",
    component: _23563732,
    name: "about-us-impact___th"
  }, {
    path: "/th/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___th"
  }, {
    path: "/th/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___th"
  }, {
    path: "/th/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___th"
  }, {
    path: "/th/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___th"
  }, {
    path: "/th/order/fail",
    component: _656aaf9f,
    name: "order-fail___th"
  }, {
    path: "/th/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___th"
  }, {
    path: "/th/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___th"
  }, {
    path: "/th/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___th"
  }, {
    path: "/th/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___th"
  }, {
    path: "/th/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___th"
  }, {
    path: "/th/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___th"
  }, {
    path: "/tr/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___tr"
  }, {
    path: "/tr/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___tr"
  }, {
    path: "/tr/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___tr"
  }, {
    path: "/tr/about-us/impact",
    component: _23563732,
    name: "about-us-impact___tr"
  }, {
    path: "/tr/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___tr"
  }, {
    path: "/tr/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___tr"
  }, {
    path: "/tr/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___tr"
  }, {
    path: "/tr/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___tr"
  }, {
    path: "/tr/order/fail",
    component: _656aaf9f,
    name: "order-fail___tr"
  }, {
    path: "/tr/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___tr"
  }, {
    path: "/tr/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___tr"
  }, {
    path: "/tr/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___tr"
  }, {
    path: "/tr/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___tr"
  }, {
    path: "/tr/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___tr"
  }, {
    path: "/tr/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___tr"
  }, {
    path: "/uk/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___uk"
  }, {
    path: "/uk/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___uk"
  }, {
    path: "/uk/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___uk"
  }, {
    path: "/uk/about-us/impact",
    component: _23563732,
    name: "about-us-impact___uk"
  }, {
    path: "/uk/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___uk"
  }, {
    path: "/uk/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___uk"
  }, {
    path: "/uk/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___uk"
  }, {
    path: "/uk/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___uk"
  }, {
    path: "/uk/order/fail",
    component: _656aaf9f,
    name: "order-fail___uk"
  }, {
    path: "/uk/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___uk"
  }, {
    path: "/uk/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___uk"
  }, {
    path: "/uk/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___uk"
  }, {
    path: "/uk/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___uk"
  }, {
    path: "/uk/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___uk"
  }, {
    path: "/uk/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___uk"
  }, {
    path: "/ur-PK/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___ur-PK"
  }, {
    path: "/ur-PK/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___ur-PK"
  }, {
    path: "/ur-PK/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___ur-PK"
  }, {
    path: "/ur-PK/about-us/impact",
    component: _23563732,
    name: "about-us-impact___ur-PK"
  }, {
    path: "/ur-PK/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___ur-PK"
  }, {
    path: "/ur-PK/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___ur-PK"
  }, {
    path: "/ur-PK/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___ur-PK"
  }, {
    path: "/ur-PK/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___ur-PK"
  }, {
    path: "/ur-PK/order/fail",
    component: _656aaf9f,
    name: "order-fail___ur-PK"
  }, {
    path: "/ur-PK/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___ur-PK"
  }, {
    path: "/ur-PK/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___ur-PK"
  }, {
    path: "/ur-PK/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___ur-PK"
  }, {
    path: "/ur-PK/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___ur-PK"
  }, {
    path: "/ur-PK/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___ur-PK"
  }, {
    path: "/ur-PK/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___ur-PK"
  }, {
    path: "/uz/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___uz"
  }, {
    path: "/uz/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___uz"
  }, {
    path: "/uz/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___uz"
  }, {
    path: "/uz/about-us/impact",
    component: _23563732,
    name: "about-us-impact___uz"
  }, {
    path: "/uz/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___uz"
  }, {
    path: "/uz/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___uz"
  }, {
    path: "/uz/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___uz"
  }, {
    path: "/uz/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___uz"
  }, {
    path: "/uz/order/fail",
    component: _656aaf9f,
    name: "order-fail___uz"
  }, {
    path: "/uz/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___uz"
  }, {
    path: "/uz/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___uz"
  }, {
    path: "/uz/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___uz"
  }, {
    path: "/uz/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___uz"
  }, {
    path: "/uz/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___uz"
  }, {
    path: "/uz/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___uz"
  }, {
    path: "/vi/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___vi"
  }, {
    path: "/vi/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___vi"
  }, {
    path: "/vi/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___vi"
  }, {
    path: "/vi/about-us/impact",
    component: _23563732,
    name: "about-us-impact___vi"
  }, {
    path: "/vi/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___vi"
  }, {
    path: "/vi/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___vi"
  }, {
    path: "/vi/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___vi"
  }, {
    path: "/vi/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___vi"
  }, {
    path: "/vi/order/fail",
    component: _656aaf9f,
    name: "order-fail___vi"
  }, {
    path: "/vi/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___vi"
  }, {
    path: "/vi/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___vi"
  }, {
    path: "/vi/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___vi"
  }, {
    path: "/vi/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___vi"
  }, {
    path: "/vi/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___vi"
  }, {
    path: "/vi/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___vi"
  }, {
    path: "/vls-BE/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___vls-BE"
  }, {
    path: "/vls-BE/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___vls-BE"
  }, {
    path: "/vls-BE/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___vls-BE"
  }, {
    path: "/vls-BE/about-us/impact",
    component: _23563732,
    name: "about-us-impact___vls-BE"
  }, {
    path: "/vls-BE/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___vls-BE"
  }, {
    path: "/vls-BE/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___vls-BE"
  }, {
    path: "/vls-BE/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___vls-BE"
  }, {
    path: "/vls-BE/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___vls-BE"
  }, {
    path: "/vls-BE/order/fail",
    component: _656aaf9f,
    name: "order-fail___vls-BE"
  }, {
    path: "/vls-BE/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___vls-BE"
  }, {
    path: "/vls-BE/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___vls-BE"
  }, {
    path: "/vls-BE/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___vls-BE"
  }, {
    path: "/vls-BE/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___vls-BE"
  }, {
    path: "/vls-BE/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___vls-BE"
  }, {
    path: "/vls-BE/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___vls-BE"
  }, {
    path: "/zh-CN/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___zh-CN"
  }, {
    path: "/zh-CN/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___zh-CN"
  }, {
    path: "/zh-CN/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___zh-CN"
  }, {
    path: "/zh-CN/about-us/impact",
    component: _23563732,
    name: "about-us-impact___zh-CN"
  }, {
    path: "/zh-CN/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___zh-CN"
  }, {
    path: "/zh-CN/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___zh-CN"
  }, {
    path: "/zh-CN/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___zh-CN"
  }, {
    path: "/zh-CN/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___zh-CN"
  }, {
    path: "/zh-CN/order/fail",
    component: _656aaf9f,
    name: "order-fail___zh-CN"
  }, {
    path: "/zh-CN/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___zh-CN"
  }, {
    path: "/zh-CN/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___zh-CN"
  }, {
    path: "/zh-CN/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___zh-CN"
  }, {
    path: "/zh-CN/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___zh-CN"
  }, {
    path: "/zh-CN/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___zh-CN"
  }, {
    path: "/zh-CN/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___zh-CN"
  }, {
    path: "/zh-TW/about-us/abraham-burak",
    component: _aec9ad62,
    name: "about-us-abraham-burak___zh-TW"
  }, {
    path: "/zh-TW/about-us/ahmet-bahadir-ozdemir",
    component: _06fe1ae9,
    name: "about-us-ahmet-bahadir-ozdemir___zh-TW"
  }, {
    path: "/zh-TW/about-us/founders",
    component: _0c7ff970,
    name: "about-us-founders___zh-TW"
  }, {
    path: "/zh-TW/about-us/impact",
    component: _23563732,
    name: "about-us-impact___zh-TW"
  }, {
    path: "/zh-TW/about-us/our-values",
    component: _7e8f0238,
    name: "about-us-our-values___zh-TW"
  }, {
    path: "/zh-TW/airalo-careers/job-vacancies",
    component: _418bb65f,
    name: "airalo-careers-job-vacancies___zh-TW"
  }, {
    path: "/zh-TW/airalo-careers/working-at-airalo",
    component: _dd08bda4,
    name: "airalo-careers-working-at-airalo___zh-TW"
  }, {
    path: "/zh-TW/esim-buy/fail",
    component: _5e02c11c,
    name: "esim-buy-fail___zh-TW"
  }, {
    path: "/zh-TW/order/fail",
    component: _656aaf9f,
    name: "order-fail___zh-TW"
  }, {
    path: "/zh-TW/partner-with-us/affiliate-partners",
    component: _555c637a,
    name: "partner-with-us-affiliate-partners___zh-TW"
  }, {
    path: "/zh-TW/partner-with-us/airalo-for-corporates",
    component: _78bc0c74,
    name: "partner-with-us-airalo-for-corporates___zh-TW"
  }, {
    path: "/zh-TW/partner-with-us/airalo-partner-hub",
    component: _91f4aae8,
    name: "partner-with-us-airalo-partner-hub___zh-TW"
  }, {
    path: "/zh-TW/partner-with-us/api-partners",
    component: _3613590d,
    name: "partner-with-us-api-partners___zh-TW"
  }, {
    path: "/zh-TW/partner-with-us/distribution-partners",
    component: _132cb1d5,
    name: "partner-with-us-distribution-partners___zh-TW"
  }, {
    path: "/zh-TW/referral/referral-terms-and-conditions",
    component: _a2e680dc,
    name: "referral-referral-terms-and-conditions___zh-TW"
  }, {
    path: "/",
    component: _2e728222,
    name: "index___en",
    children: [{
      path: ":list-esim",
      component: _c9b54b60,
      name: "index-list___en",
      children: [{
        path: ":package?",
        component: _4d5d6b16,
        name: "index-list-package___en"
      }]
    }]
  }, {
    path: "/ar/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ar",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ar",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ar"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ar"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ar",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ar",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ar"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ar",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ar"
        }]
      }]
    }]
  }, {
    path: "/az/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___az",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___az",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___az"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___az"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___az",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___az",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___az"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___az",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___az"
        }]
      }]
    }]
  }, {
    path: "/bg/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___bg",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___bg",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___bg"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___bg"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___bg",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___bg",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___bg"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___bg",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___bg"
        }]
      }]
    }]
  }, {
    path: "/bs/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___bs",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___bs",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___bs"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___bs"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___bs",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___bs",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___bs"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___bs",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___bs"
        }]
      }]
    }]
  }, {
    path: "/cs/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___cs",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___cs",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___cs"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___cs"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___cs",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___cs",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___cs"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___cs",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___cs"
        }]
      }]
    }]
  }, {
    path: "/da/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___da",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___da",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___da"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___da"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___da",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___da",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___da"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___da",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___da"
        }]
      }]
    }]
  }, {
    path: "/de/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___de",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___de",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___de"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___de"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___de",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___de",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___de"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___de",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___de"
        }]
      }]
    }]
  }, {
    path: "/el/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___el",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___el",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___el"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___el"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___el",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___el",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___el"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___el",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___el"
        }]
      }]
    }]
  }, {
    path: "/es-419/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___es-419",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___es-419",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___es-419"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___es-419"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___es-419",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___es-419",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___es-419"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___es-419",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___es-419"
        }]
      }]
    }]
  }, {
    path: "/es-ES/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___es-ES",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___es-ES",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___es-ES"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___es-ES"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___es-ES",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___es-ES",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___es-ES"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___es-ES",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___es-ES"
        }]
      }]
    }]
  }, {
    path: "/et/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___et",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___et",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___et"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___et"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___et",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___et",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___et"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___et",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___et"
        }]
      }]
    }]
  }, {
    path: "/fa/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___fa",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___fa",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___fa"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___fa"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___fa",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___fa",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___fa"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___fa",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___fa"
        }]
      }]
    }]
  }, {
    path: "/fi/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___fi",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___fi",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___fi"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___fi"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___fi",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___fi",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___fi"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___fi",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___fi"
        }]
      }]
    }]
  }, {
    path: "/fil/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___fil",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___fil",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___fil"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___fil"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___fil",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___fil",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___fil"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___fil",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___fil"
        }]
      }]
    }]
  }, {
    path: "/fr/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___fr",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___fr",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___fr"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___fr"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___fr",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___fr",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___fr"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___fr",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___fr"
        }]
      }]
    }]
  }, {
    path: "/he/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___he",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___he",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___he"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___he"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___he",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___he",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___he"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___he",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___he"
        }]
      }]
    }]
  }, {
    path: "/hi/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___hi",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___hi",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___hi"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___hi"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___hi",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___hi",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___hi"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___hi",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___hi"
        }]
      }]
    }]
  }, {
    path: "/hr/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___hr",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___hr",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___hr"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___hr"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___hr",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___hr",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___hr"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___hr",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___hr"
        }]
      }]
    }]
  }, {
    path: "/hu/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___hu",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___hu",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___hu"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___hu"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___hu",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___hu",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___hu"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___hu",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___hu"
        }]
      }]
    }]
  }, {
    path: "/hy-AM/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___hy-AM",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___hy-AM",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___hy-AM"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___hy-AM"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___hy-AM",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___hy-AM",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___hy-AM"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___hy-AM",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___hy-AM"
        }]
      }]
    }]
  }, {
    path: "/id/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___id",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___id",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___id"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___id"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___id",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___id",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___id"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___id",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___id"
        }]
      }]
    }]
  }, {
    path: "/it/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___it",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___it",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___it"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___it"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___it",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___it",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___it"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___it",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___it"
        }]
      }]
    }]
  }, {
    path: "/ja/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ja",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ja",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ja"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ja"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ja",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ja",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ja"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ja",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ja"
        }]
      }]
    }]
  }, {
    path: "/ka/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ka",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ka",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ka"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ka"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ka",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ka",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ka"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ka",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ka"
        }]
      }]
    }]
  }, {
    path: "/kk/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___kk",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___kk",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___kk"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___kk"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___kk",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___kk",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___kk"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___kk",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___kk"
        }]
      }]
    }]
  }, {
    path: "/kmr/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___kmr",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___kmr",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___kmr"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___kmr"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___kmr",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___kmr",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___kmr"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___kmr",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___kmr"
        }]
      }]
    }]
  }, {
    path: "/ko/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ko",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ko",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ko"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ko"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ko",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ko",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ko"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ko",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ko"
        }]
      }]
    }]
  }, {
    path: "/ky/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ky",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ky",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ky"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ky"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ky",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ky",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ky"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ky",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ky"
        }]
      }]
    }]
  }, {
    path: "/lt/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___lt",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___lt",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___lt"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___lt"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___lt",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___lt",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___lt"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___lt",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___lt"
        }]
      }]
    }]
  }, {
    path: "/lv/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___lv",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___lv",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___lv"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___lv"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___lv",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___lv",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___lv"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___lv",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___lv"
        }]
      }]
    }]
  }, {
    path: "/mk/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___mk",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___mk",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___mk"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___mk"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___mk",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___mk",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___mk"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___mk",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___mk"
        }]
      }]
    }]
  }, {
    path: "/ms/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ms",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ms",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ms"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ms"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ms",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ms",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ms"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ms",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ms"
        }]
      }]
    }]
  }, {
    path: "/nb/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___nb",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___nb",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___nb"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___nb"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___nb",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___nb",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___nb"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___nb",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___nb"
        }]
      }]
    }]
  }, {
    path: "/nl/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___nl",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___nl",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___nl"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___nl"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___nl",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___nl",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___nl"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___nl",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___nl"
        }]
      }]
    }]
  }, {
    path: "/pl/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___pl",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___pl",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___pl"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___pl"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___pl",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___pl",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___pl"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___pl",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___pl"
        }]
      }]
    }]
  }, {
    path: "/pt-BR/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___pt-BR",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___pt-BR",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___pt-BR"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___pt-BR"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___pt-BR",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___pt-BR",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___pt-BR"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___pt-BR",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___pt-BR"
        }]
      }]
    }]
  }, {
    path: "/pt-PT/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___pt-PT",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___pt-PT",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___pt-PT"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___pt-PT"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___pt-PT",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___pt-PT",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___pt-PT"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___pt-PT",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___pt-PT"
        }]
      }]
    }]
  }, {
    path: "/ro/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ro",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ro",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ro"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ro"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ro",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ro",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ro"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ro",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ro"
        }]
      }]
    }]
  }, {
    path: "/ru/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ru",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ru",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ru"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ru"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ru",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ru",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ru"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ru",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ru"
        }]
      }]
    }]
  }, {
    path: "/sk/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___sk",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___sk",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___sk"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___sk"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___sk",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___sk",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___sk"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___sk",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___sk"
        }]
      }]
    }]
  }, {
    path: "/sq/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___sq",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___sq",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___sq"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___sq"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___sq",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___sq",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___sq"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___sq",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___sq"
        }]
      }]
    }]
  }, {
    path: "/sr-CS/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___sr-CS",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___sr-CS",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___sr-CS"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___sr-CS"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___sr-CS",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___sr-CS",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___sr-CS"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___sr-CS",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___sr-CS"
        }]
      }]
    }]
  }, {
    path: "/sv-SE/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___sv-SE",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___sv-SE",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___sv-SE"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___sv-SE"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___sv-SE",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___sv-SE",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___sv-SE"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___sv-SE",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___sv-SE"
        }]
      }]
    }]
  }, {
    path: "/th/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___th",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___th",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___th"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___th"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___th",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___th",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___th"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___th",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___th"
        }]
      }]
    }]
  }, {
    path: "/tr/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___tr",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___tr",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___tr"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___tr"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___tr",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___tr",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___tr"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___tr",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___tr"
        }]
      }]
    }]
  }, {
    path: "/uk/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___uk",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___uk",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___uk"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___uk"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___uk",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___uk",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___uk"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___uk",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___uk"
        }]
      }]
    }]
  }, {
    path: "/ur-PK/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___ur-PK",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___ur-PK",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___ur-PK"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___ur-PK"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___ur-PK",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___ur-PK",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___ur-PK"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___ur-PK",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___ur-PK"
        }]
      }]
    }]
  }, {
    path: "/uz/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___uz",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___uz",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___uz"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___uz"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___uz",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___uz",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___uz"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___uz",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___uz"
        }]
      }]
    }]
  }, {
    path: "/vi/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___vi",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___vi",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___vi"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___vi"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___vi",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___vi",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___vi"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___vi",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___vi"
        }]
      }]
    }]
  }, {
    path: "/vls-BE/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___vls-BE",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___vls-BE",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___vls-BE"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___vls-BE"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___vls-BE",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___vls-BE",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___vls-BE"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___vls-BE",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___vls-BE"
        }]
      }]
    }]
  }, {
    path: "/zh-CN/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___zh-CN",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___zh-CN",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___zh-CN"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___zh-CN"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___zh-CN",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___zh-CN",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___zh-CN"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___zh-CN",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___zh-CN"
        }]
      }]
    }]
  }, {
    path: "/zh-TW/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___zh-TW",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___zh-TW",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___zh-TW"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___zh-TW"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___zh-TW",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___zh-TW",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___zh-TW"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___zh-TW",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___zh-TW"
        }]
      }]
    }]
  }, {
    path: "/ar/blog/:title",
    component: _351581b6,
    name: "blog-title___ar"
  }, {
    path: "/ar/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ar"
  }, {
    path: "/ar/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ar",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ar"
    }]
  }, {
    path: "/ar/order/:id",
    component: _2661bd0c,
    name: "order-id___ar"
  }, {
    path: "/az/blog/:title",
    component: _351581b6,
    name: "blog-title___az"
  }, {
    path: "/az/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___az"
  }, {
    path: "/az/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___az",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___az"
    }]
  }, {
    path: "/az/order/:id",
    component: _2661bd0c,
    name: "order-id___az"
  }, {
    path: "/bg/blog/:title",
    component: _351581b6,
    name: "blog-title___bg"
  }, {
    path: "/bg/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___bg"
  }, {
    path: "/bg/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___bg",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___bg"
    }]
  }, {
    path: "/bg/order/:id",
    component: _2661bd0c,
    name: "order-id___bg"
  }, {
    path: "/bs/blog/:title",
    component: _351581b6,
    name: "blog-title___bs"
  }, {
    path: "/bs/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___bs"
  }, {
    path: "/bs/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___bs",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___bs"
    }]
  }, {
    path: "/bs/order/:id",
    component: _2661bd0c,
    name: "order-id___bs"
  }, {
    path: "/cs/blog/:title",
    component: _351581b6,
    name: "blog-title___cs"
  }, {
    path: "/cs/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___cs"
  }, {
    path: "/cs/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___cs",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___cs"
    }]
  }, {
    path: "/cs/order/:id",
    component: _2661bd0c,
    name: "order-id___cs"
  }, {
    path: "/da/blog/:title",
    component: _351581b6,
    name: "blog-title___da"
  }, {
    path: "/da/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___da"
  }, {
    path: "/da/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___da",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___da"
    }]
  }, {
    path: "/da/order/:id",
    component: _2661bd0c,
    name: "order-id___da"
  }, {
    path: "/de/blog/:title",
    component: _351581b6,
    name: "blog-title___de"
  }, {
    path: "/de/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___de"
  }, {
    path: "/de/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___de",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___de"
    }]
  }, {
    path: "/de/order/:id",
    component: _2661bd0c,
    name: "order-id___de"
  }, {
    path: "/el/blog/:title",
    component: _351581b6,
    name: "blog-title___el"
  }, {
    path: "/el/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___el"
  }, {
    path: "/el/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___el",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___el"
    }]
  }, {
    path: "/el/order/:id",
    component: _2661bd0c,
    name: "order-id___el"
  }, {
    path: "/es-419/blog/:title",
    component: _351581b6,
    name: "blog-title___es-419"
  }, {
    path: "/es-419/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___es-419"
  }, {
    path: "/es-419/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___es-419",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___es-419"
    }]
  }, {
    path: "/es-419/order/:id",
    component: _2661bd0c,
    name: "order-id___es-419"
  }, {
    path: "/es-ES/blog/:title",
    component: _351581b6,
    name: "blog-title___es-ES"
  }, {
    path: "/es-ES/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___es-ES"
  }, {
    path: "/es-ES/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___es-ES",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___es-ES"
    }]
  }, {
    path: "/es-ES/order/:id",
    component: _2661bd0c,
    name: "order-id___es-ES"
  }, {
    path: "/et/blog/:title",
    component: _351581b6,
    name: "blog-title___et"
  }, {
    path: "/et/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___et"
  }, {
    path: "/et/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___et",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___et"
    }]
  }, {
    path: "/et/order/:id",
    component: _2661bd0c,
    name: "order-id___et"
  }, {
    path: "/fa/blog/:title",
    component: _351581b6,
    name: "blog-title___fa"
  }, {
    path: "/fa/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___fa"
  }, {
    path: "/fa/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___fa",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___fa"
    }]
  }, {
    path: "/fa/order/:id",
    component: _2661bd0c,
    name: "order-id___fa"
  }, {
    path: "/fi/blog/:title",
    component: _351581b6,
    name: "blog-title___fi"
  }, {
    path: "/fi/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___fi"
  }, {
    path: "/fi/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___fi",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___fi"
    }]
  }, {
    path: "/fi/order/:id",
    component: _2661bd0c,
    name: "order-id___fi"
  }, {
    path: "/fil/blog/:title",
    component: _351581b6,
    name: "blog-title___fil"
  }, {
    path: "/fil/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___fil"
  }, {
    path: "/fil/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___fil",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___fil"
    }]
  }, {
    path: "/fil/order/:id",
    component: _2661bd0c,
    name: "order-id___fil"
  }, {
    path: "/fr/blog/:title",
    component: _351581b6,
    name: "blog-title___fr"
  }, {
    path: "/fr/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___fr"
  }, {
    path: "/fr/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___fr",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___fr"
    }]
  }, {
    path: "/fr/order/:id",
    component: _2661bd0c,
    name: "order-id___fr"
  }, {
    path: "/he/blog/:title",
    component: _351581b6,
    name: "blog-title___he"
  }, {
    path: "/he/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___he"
  }, {
    path: "/he/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___he",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___he"
    }]
  }, {
    path: "/he/order/:id",
    component: _2661bd0c,
    name: "order-id___he"
  }, {
    path: "/hi/blog/:title",
    component: _351581b6,
    name: "blog-title___hi"
  }, {
    path: "/hi/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___hi"
  }, {
    path: "/hi/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___hi",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___hi"
    }]
  }, {
    path: "/hi/order/:id",
    component: _2661bd0c,
    name: "order-id___hi"
  }, {
    path: "/hr/blog/:title",
    component: _351581b6,
    name: "blog-title___hr"
  }, {
    path: "/hr/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___hr"
  }, {
    path: "/hr/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___hr",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___hr"
    }]
  }, {
    path: "/hr/order/:id",
    component: _2661bd0c,
    name: "order-id___hr"
  }, {
    path: "/hu/blog/:title",
    component: _351581b6,
    name: "blog-title___hu"
  }, {
    path: "/hu/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___hu"
  }, {
    path: "/hu/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___hu",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___hu"
    }]
  }, {
    path: "/hu/order/:id",
    component: _2661bd0c,
    name: "order-id___hu"
  }, {
    path: "/hy-AM/blog/:title",
    component: _351581b6,
    name: "blog-title___hy-AM"
  }, {
    path: "/hy-AM/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___hy-AM"
  }, {
    path: "/hy-AM/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___hy-AM",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___hy-AM"
    }]
  }, {
    path: "/hy-AM/order/:id",
    component: _2661bd0c,
    name: "order-id___hy-AM"
  }, {
    path: "/id/blog/:title",
    component: _351581b6,
    name: "blog-title___id"
  }, {
    path: "/id/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___id"
  }, {
    path: "/id/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___id",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___id"
    }]
  }, {
    path: "/id/order/:id",
    component: _2661bd0c,
    name: "order-id___id"
  }, {
    path: "/it/blog/:title",
    component: _351581b6,
    name: "blog-title___it"
  }, {
    path: "/it/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___it"
  }, {
    path: "/it/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___it",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___it"
    }]
  }, {
    path: "/it/order/:id",
    component: _2661bd0c,
    name: "order-id___it"
  }, {
    path: "/ja/blog/:title",
    component: _351581b6,
    name: "blog-title___ja"
  }, {
    path: "/ja/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ja"
  }, {
    path: "/ja/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ja",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ja"
    }]
  }, {
    path: "/ja/order/:id",
    component: _2661bd0c,
    name: "order-id___ja"
  }, {
    path: "/ka/blog/:title",
    component: _351581b6,
    name: "blog-title___ka"
  }, {
    path: "/ka/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ka"
  }, {
    path: "/ka/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ka",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ka"
    }]
  }, {
    path: "/ka/order/:id",
    component: _2661bd0c,
    name: "order-id___ka"
  }, {
    path: "/kk/blog/:title",
    component: _351581b6,
    name: "blog-title___kk"
  }, {
    path: "/kk/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___kk"
  }, {
    path: "/kk/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___kk",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___kk"
    }]
  }, {
    path: "/kk/order/:id",
    component: _2661bd0c,
    name: "order-id___kk"
  }, {
    path: "/kmr/blog/:title",
    component: _351581b6,
    name: "blog-title___kmr"
  }, {
    path: "/kmr/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___kmr"
  }, {
    path: "/kmr/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___kmr",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___kmr"
    }]
  }, {
    path: "/kmr/order/:id",
    component: _2661bd0c,
    name: "order-id___kmr"
  }, {
    path: "/ko/blog/:title",
    component: _351581b6,
    name: "blog-title___ko"
  }, {
    path: "/ko/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ko"
  }, {
    path: "/ko/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ko",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ko"
    }]
  }, {
    path: "/ko/order/:id",
    component: _2661bd0c,
    name: "order-id___ko"
  }, {
    path: "/ky/blog/:title",
    component: _351581b6,
    name: "blog-title___ky"
  }, {
    path: "/ky/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ky"
  }, {
    path: "/ky/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ky",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ky"
    }]
  }, {
    path: "/ky/order/:id",
    component: _2661bd0c,
    name: "order-id___ky"
  }, {
    path: "/lt/blog/:title",
    component: _351581b6,
    name: "blog-title___lt"
  }, {
    path: "/lt/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___lt"
  }, {
    path: "/lt/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___lt",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___lt"
    }]
  }, {
    path: "/lt/order/:id",
    component: _2661bd0c,
    name: "order-id___lt"
  }, {
    path: "/lv/blog/:title",
    component: _351581b6,
    name: "blog-title___lv"
  }, {
    path: "/lv/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___lv"
  }, {
    path: "/lv/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___lv",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___lv"
    }]
  }, {
    path: "/lv/order/:id",
    component: _2661bd0c,
    name: "order-id___lv"
  }, {
    path: "/mk/blog/:title",
    component: _351581b6,
    name: "blog-title___mk"
  }, {
    path: "/mk/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___mk"
  }, {
    path: "/mk/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___mk",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___mk"
    }]
  }, {
    path: "/mk/order/:id",
    component: _2661bd0c,
    name: "order-id___mk"
  }, {
    path: "/ms/blog/:title",
    component: _351581b6,
    name: "blog-title___ms"
  }, {
    path: "/ms/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ms"
  }, {
    path: "/ms/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ms",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ms"
    }]
  }, {
    path: "/ms/order/:id",
    component: _2661bd0c,
    name: "order-id___ms"
  }, {
    path: "/my-esims/detail/:id",
    component: _8ec1938a,
    name: "my-esims-detail-id___en",
    children: [{
      path: "installation/android",
      component: _05548ebe,
      name: "my-esims-detail-id-installation-android___en",
      children: [{
        path: "manual",
        component: _5e91e6a4,
        name: "my-esims-detail-id-installation-android-index-manual___en"
      }, {
        path: "qr",
        component: _0694fa7f,
        name: "my-esims-detail-id-installation-android-index-qr___en"
      }]
    }, {
      path: "installation/ios",
      component: _065cb03f,
      name: "my-esims-detail-id-installation-ios___en",
      children: [{
        path: "manual",
        component: _bbde7d74,
        name: "my-esims-detail-id-installation-ios-index-manual___en",
        children: [{
          path: "step-by-step-guide",
          component: _f46eb26e,
          name: "my-esims-detail-id-installation-ios-index-manual-step-by-step-guide___en"
        }]
      }, {
        path: "qr",
        component: _ef4313be,
        name: "my-esims-detail-id-installation-ios-index-qr___en",
        children: [{
          path: "step-by-step-guide",
          component: _4c88d60e,
          name: "my-esims-detail-id-installation-ios-index-qr-step-by-step-guide___en"
        }]
      }]
    }]
  }, {
    path: "/nb/blog/:title",
    component: _351581b6,
    name: "blog-title___nb"
  }, {
    path: "/nb/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___nb"
  }, {
    path: "/nb/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___nb",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___nb"
    }]
  }, {
    path: "/nb/order/:id",
    component: _2661bd0c,
    name: "order-id___nb"
  }, {
    path: "/nl/blog/:title",
    component: _351581b6,
    name: "blog-title___nl"
  }, {
    path: "/nl/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___nl"
  }, {
    path: "/nl/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___nl",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___nl"
    }]
  }, {
    path: "/nl/order/:id",
    component: _2661bd0c,
    name: "order-id___nl"
  }, {
    path: "/pl/blog/:title",
    component: _351581b6,
    name: "blog-title___pl"
  }, {
    path: "/pl/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___pl"
  }, {
    path: "/pl/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___pl",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___pl"
    }]
  }, {
    path: "/pl/order/:id",
    component: _2661bd0c,
    name: "order-id___pl"
  }, {
    path: "/pt-BR/blog/:title",
    component: _351581b6,
    name: "blog-title___pt-BR"
  }, {
    path: "/pt-BR/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___pt-BR"
  }, {
    path: "/pt-BR/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___pt-BR",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___pt-BR"
    }]
  }, {
    path: "/pt-BR/order/:id",
    component: _2661bd0c,
    name: "order-id___pt-BR"
  }, {
    path: "/pt-PT/blog/:title",
    component: _351581b6,
    name: "blog-title___pt-PT"
  }, {
    path: "/pt-PT/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___pt-PT"
  }, {
    path: "/pt-PT/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___pt-PT",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___pt-PT"
    }]
  }, {
    path: "/pt-PT/order/:id",
    component: _2661bd0c,
    name: "order-id___pt-PT"
  }, {
    path: "/ro/blog/:title",
    component: _351581b6,
    name: "blog-title___ro"
  }, {
    path: "/ro/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ro"
  }, {
    path: "/ro/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ro",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ro"
    }]
  }, {
    path: "/ro/order/:id",
    component: _2661bd0c,
    name: "order-id___ro"
  }, {
    path: "/ru/blog/:title",
    component: _351581b6,
    name: "blog-title___ru"
  }, {
    path: "/ru/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ru"
  }, {
    path: "/ru/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ru",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ru"
    }]
  }, {
    path: "/ru/order/:id",
    component: _2661bd0c,
    name: "order-id___ru"
  }, {
    path: "/sk/blog/:title",
    component: _351581b6,
    name: "blog-title___sk"
  }, {
    path: "/sk/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___sk"
  }, {
    path: "/sk/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___sk",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___sk"
    }]
  }, {
    path: "/sk/order/:id",
    component: _2661bd0c,
    name: "order-id___sk"
  }, {
    path: "/sq/blog/:title",
    component: _351581b6,
    name: "blog-title___sq"
  }, {
    path: "/sq/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___sq"
  }, {
    path: "/sq/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___sq",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___sq"
    }]
  }, {
    path: "/sq/order/:id",
    component: _2661bd0c,
    name: "order-id___sq"
  }, {
    path: "/sr-CS/blog/:title",
    component: _351581b6,
    name: "blog-title___sr-CS"
  }, {
    path: "/sr-CS/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___sr-CS"
  }, {
    path: "/sr-CS/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___sr-CS",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___sr-CS"
    }]
  }, {
    path: "/sr-CS/order/:id",
    component: _2661bd0c,
    name: "order-id___sr-CS"
  }, {
    path: "/sv-SE/blog/:title",
    component: _351581b6,
    name: "blog-title___sv-SE"
  }, {
    path: "/sv-SE/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___sv-SE"
  }, {
    path: "/sv-SE/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___sv-SE",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___sv-SE"
    }]
  }, {
    path: "/sv-SE/order/:id",
    component: _2661bd0c,
    name: "order-id___sv-SE"
  }, {
    path: "/th/blog/:title",
    component: _351581b6,
    name: "blog-title___th"
  }, {
    path: "/th/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___th"
  }, {
    path: "/th/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___th",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___th"
    }]
  }, {
    path: "/th/order/:id",
    component: _2661bd0c,
    name: "order-id___th"
  }, {
    path: "/tr/blog/:title",
    component: _351581b6,
    name: "blog-title___tr"
  }, {
    path: "/tr/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___tr"
  }, {
    path: "/tr/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___tr",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___tr"
    }]
  }, {
    path: "/tr/order/:id",
    component: _2661bd0c,
    name: "order-id___tr"
  }, {
    path: "/uk/blog/:title",
    component: _351581b6,
    name: "blog-title___uk"
  }, {
    path: "/uk/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___uk"
  }, {
    path: "/uk/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___uk",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___uk"
    }]
  }, {
    path: "/uk/order/:id",
    component: _2661bd0c,
    name: "order-id___uk"
  }, {
    path: "/ur-PK/blog/:title",
    component: _351581b6,
    name: "blog-title___ur-PK"
  }, {
    path: "/ur-PK/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___ur-PK"
  }, {
    path: "/ur-PK/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___ur-PK",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___ur-PK"
    }]
  }, {
    path: "/ur-PK/order/:id",
    component: _2661bd0c,
    name: "order-id___ur-PK"
  }, {
    path: "/uz/blog/:title",
    component: _351581b6,
    name: "blog-title___uz"
  }, {
    path: "/uz/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___uz"
  }, {
    path: "/uz/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___uz",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___uz"
    }]
  }, {
    path: "/uz/order/:id",
    component: _2661bd0c,
    name: "order-id___uz"
  }, {
    path: "/vi/blog/:title",
    component: _351581b6,
    name: "blog-title___vi"
  }, {
    path: "/vi/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___vi"
  }, {
    path: "/vi/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___vi",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___vi"
    }]
  }, {
    path: "/vi/order/:id",
    component: _2661bd0c,
    name: "order-id___vi"
  }, {
    path: "/vls-BE/blog/:title",
    component: _351581b6,
    name: "blog-title___vls-BE"
  }, {
    path: "/vls-BE/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___vls-BE"
  }, {
    path: "/vls-BE/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___vls-BE",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___vls-BE"
    }]
  }, {
    path: "/vls-BE/order/:id",
    component: _2661bd0c,
    name: "order-id___vls-BE"
  }, {
    path: "/zh-CN/blog/:title",
    component: _351581b6,
    name: "blog-title___zh-CN"
  }, {
    path: "/zh-CN/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___zh-CN"
  }, {
    path: "/zh-CN/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___zh-CN",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___zh-CN"
    }]
  }, {
    path: "/zh-CN/order/:id",
    component: _2661bd0c,
    name: "order-id___zh-CN"
  }, {
    path: "/zh-TW/blog/:title",
    component: _351581b6,
    name: "blog-title___zh-TW"
  }, {
    path: "/zh-TW/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___zh-TW"
  }, {
    path: "/zh-TW/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___zh-TW",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___zh-TW"
    }]
  }, {
    path: "/zh-TW/order/:id",
    component: _2661bd0c,
    name: "order-id___zh-TW"
  }, {
    path: "/ar/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ar"
  }, {
    path: "/ar/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ar"
  }, {
    path: "/az/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___az"
  }, {
    path: "/az/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___az"
  }, {
    path: "/bg/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___bg"
  }, {
    path: "/bg/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___bg"
  }, {
    path: "/bs/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___bs"
  }, {
    path: "/bs/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___bs"
  }, {
    path: "/cs/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___cs"
  }, {
    path: "/cs/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___cs"
  }, {
    path: "/da/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___da"
  }, {
    path: "/da/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___da"
  }, {
    path: "/de/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___de"
  }, {
    path: "/de/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___de"
  }, {
    path: "/el/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___el"
  }, {
    path: "/el/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___el"
  }, {
    path: "/es-419/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___es-419"
  }, {
    path: "/es-419/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___es-419"
  }, {
    path: "/es-ES/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___es-ES"
  }, {
    path: "/es-ES/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___es-ES"
  }, {
    path: "/et/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___et"
  }, {
    path: "/et/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___et"
  }, {
    path: "/fa/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___fa"
  }, {
    path: "/fa/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___fa"
  }, {
    path: "/fi/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___fi"
  }, {
    path: "/fi/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___fi"
  }, {
    path: "/fil/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___fil"
  }, {
    path: "/fil/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___fil"
  }, {
    path: "/fr/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___fr"
  }, {
    path: "/fr/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___fr"
  }, {
    path: "/he/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___he"
  }, {
    path: "/he/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___he"
  }, {
    path: "/hi/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___hi"
  }, {
    path: "/hi/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___hi"
  }, {
    path: "/hr/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___hr"
  }, {
    path: "/hr/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___hr"
  }, {
    path: "/hu/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___hu"
  }, {
    path: "/hu/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___hu"
  }, {
    path: "/hy-AM/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___hy-AM"
  }, {
    path: "/hy-AM/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___hy-AM"
  }, {
    path: "/id/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___id"
  }, {
    path: "/id/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___id"
  }, {
    path: "/it/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___it"
  }, {
    path: "/it/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___it"
  }, {
    path: "/ja/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ja"
  }, {
    path: "/ja/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ja"
  }, {
    path: "/ka/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ka"
  }, {
    path: "/ka/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ka"
  }, {
    path: "/kk/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___kk"
  }, {
    path: "/kk/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___kk"
  }, {
    path: "/kmr/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___kmr"
  }, {
    path: "/kmr/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___kmr"
  }, {
    path: "/ko/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ko"
  }, {
    path: "/ko/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ko"
  }, {
    path: "/ky/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ky"
  }, {
    path: "/ky/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ky"
  }, {
    path: "/lt/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___lt"
  }, {
    path: "/lt/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___lt"
  }, {
    path: "/lv/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___lv"
  }, {
    path: "/lv/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___lv"
  }, {
    path: "/mk/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___mk"
  }, {
    path: "/mk/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___mk"
  }, {
    path: "/ms/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ms"
  }, {
    path: "/ms/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ms"
  }, {
    path: "/nb/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___nb"
  }, {
    path: "/nb/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___nb"
  }, {
    path: "/nl/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___nl"
  }, {
    path: "/nl/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___nl"
  }, {
    path: "/pl/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___pl"
  }, {
    path: "/pl/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___pl"
  }, {
    path: "/pt-BR/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___pt-BR"
  }, {
    path: "/pt-BR/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___pt-BR"
  }, {
    path: "/pt-PT/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___pt-PT"
  }, {
    path: "/pt-PT/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___pt-PT"
  }, {
    path: "/ro/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ro"
  }, {
    path: "/ro/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ro"
  }, {
    path: "/ru/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ru"
  }, {
    path: "/ru/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ru"
  }, {
    path: "/sk/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___sk"
  }, {
    path: "/sk/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___sk"
  }, {
    path: "/sq/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___sq"
  }, {
    path: "/sq/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___sq"
  }, {
    path: "/sr-CS/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___sr-CS"
  }, {
    path: "/sr-CS/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___sr-CS"
  }, {
    path: "/sv-SE/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___sv-SE"
  }, {
    path: "/sv-SE/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___sv-SE"
  }, {
    path: "/th/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___th"
  }, {
    path: "/th/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___th"
  }, {
    path: "/tr/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___tr"
  }, {
    path: "/tr/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___tr"
  }, {
    path: "/uk/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___uk"
  }, {
    path: "/uk/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___uk"
  }, {
    path: "/ur-PK/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___ur-PK"
  }, {
    path: "/ur-PK/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___ur-PK"
  }, {
    path: "/uz/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___uz"
  }, {
    path: "/uz/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___uz"
  }, {
    path: "/vi/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___vi"
  }, {
    path: "/vi/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___vi"
  }, {
    path: "/vls-BE/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___vls-BE"
  }, {
    path: "/vls-BE/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___vls-BE"
  }, {
    path: "/zh-CN/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___zh-CN"
  }, {
    path: "/zh-CN/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___zh-CN"
  }, {
    path: "/zh-TW/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___zh-TW"
  }, {
    path: "/zh-TW/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___zh-TW"
  }, {
    path: "/blog/:title",
    component: _351581b6,
    name: "blog-title___en"
  }, {
    path: "/campaign-terms/:slug",
    component: _3bf3f830,
    name: "campaign-terms-slug___en"
  }, {
    path: "/esim-buy/:id",
    component: _3cf9b919,
    name: "esim-buy-id___en",
    children: [{
      path: "topup/:topup?",
      component: _650badd6,
      name: "esim-buy-id-index-topup-topup___en"
    }]
  }, {
    path: "/order/:id",
    component: _2661bd0c,
    name: "order-id___en"
  }, {
    path: "/esim-buy/:id?/cancel",
    component: _ae31597a,
    name: "esim-buy-id-cancel___en"
  }, {
    path: "/esim-buy/:id?/complete",
    component: _c15c537c,
    name: "esim-buy-id-complete___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
